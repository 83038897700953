import { useState } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import ConfigForm from "../../components/modal/ConfigForm"
import { useDispatch, useSelector } from "react-redux"
import { selectConfig } from "../../features/config/configSlice"
import { Snackbar } from "../../components/Snackbar"
import { LoadingOverlay } from "../../components/LoadingOverlay"
import BgCheck from "../../components/svg/BgCheck"
import { CheckActivity } from "../../components/CheckActivity"
import Cloud from "../../components/svg/Cloud"
import { Fullscreen } from "../../components/Fullscreen"
import Home from "../../components/svg/Home"
import { clearLoadingOverlay } from "../../features/application/appSlice"
import Bars3 from "../../components/svg/Bars3"
import XMark from "../../components/svg/XMark"
import { resetReservations } from "../../helpers/reservation"
import Button from "../../components/button/Button"

export default function Layout() {
  const [configModal, setConfigModal] = useState<boolean>(false)
  const config = useSelector(selectConfig)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showMenu, setShowMenu] = useState<boolean>(false)
  const [showCloseMessage, setShowCloseMessage] = useState<boolean>(false)
  const location = useLocation()

  return (
    <>
      {/* Snackbar and ovlerlay */}
      <Snackbar />
      <LoadingOverlay />

      {/* Background */}
      <div className="fixed inset-0 flex items-center justify-center overflow-hidden">
        <BgCheck className="w-full text-purple-300" />
      </div>

      {/* Main, where the app is rendered */}
      <div className="flex justify-center items-center w-full">
        <main className="relative flex flex-col items-center h-screen w-full max-w-2xl py-12">
          {config.Loaded && <Outlet />}
        </main>
      </div>

      {/* Config modal */}
      {configModal && (
        <ConfigForm
          onCancel={() => {
            setConfigModal(false)
          }}
        />
      )}

      {/* Check activity */}
      {location.pathname !== "/kiosk" && (
        <CheckActivity
          callback={() => {
            resetReservations()
          }}
        />
      )}

      {/* Reset button */}
      {location.pathname !== "/kiosk" && (
        <div
          className={`absolute flex justify-center items-center top-4 right-4 bg-gray-600 text-white ${
            showCloseMessage === false ? "p-4 rounded-full" : "p-12 rounded-xl"
          }`}
        >
          {showCloseMessage === false && (
            <XMark onClick={() => setShowCloseMessage(true)} />
          )}
          {showCloseMessage === true && (
            <div>
              Do you really want to stop?
              <div className="flex justify-center space-x-4 mt-4">
                <Button
                  onClick={() => {
                    setShowCloseMessage(false)
                  }}
                  text="No"
                  size="small"
                  type="button"
                />
                <Button
                  onClick={() => {
                    resetReservations()
                    setShowCloseMessage(false)
                  }}
                  text="Yes"
                  size="small"
                  type="button"
                />
              </div>
            </div>
          )}
        </div>
      )}

      {/* Menu */}
      {location.pathname === "/kiosk" && (
        <div
          className={`absolute top-4 left-4 z-50 p-2 flex flex-col space-y-4 bg-gray-600 text-white rounded-md ${
            showMenu ? "opacity-100" : "opacity-25"
          }`}
        >
          {!showMenu && <Bars3 onClick={() => setShowMenu(!showMenu)} />}
          {showMenu && (
            <>
              <XMark onClick={() => setShowMenu(!showMenu)} />
              <Home
                onClick={() => {
                  dispatch(clearLoadingOverlay())
                  document.location.href = "/"
                }}
                className="w-6 h-6"
              />
              <Cloud
                onClick={() => {
                  setConfigModal(true)
                  setShowMenu(!showMenu)
                }}
                className="w-6 h-6"
              />
              <Fullscreen
                className="w-6 h-6"
                callBack={() => setShowMenu(!showMenu)}
              />
            </>
          )}
        </div>
      )}
    </>
  )
}
