import { useEffect, useState } from "react"
import Button from "../../../components/button/Button"
import ArrowSmall from "../../../components/svg/ArrowSmall"
import { Link, useNavigate } from "react-router-dom"
import { IReservation } from "../../../features/reservation/reservationInterface"
import { useDispatch, useSelector } from "react-redux"
import {
  selectReservation,
} from "../../../features/reservation/reservationSlice"
import { findReservation } from "../../../helpers/reservation"
import { post } from "../../../helpers/api"
import { clearLoadingOverlay, setLoadingOverlay } from "../../../features/application/appSlice"
import { showSnackbar } from "../../../helpers/notification"
import NumberTile from "../../../components/button/NumberTile"
import HandThumbUp from "../../../components/svg/HandThumbUp"
import HandThumbDown from "../../../components/svg/HandThumbDown"

export default function Feedback() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const reservation = useSelector(selectReservation)
  const [reservationDetails, setReservationDetails] = useState<IReservation>()

  useEffect(() => {
    if (reservation.ActiveReservation !== undefined) {
      const r = findReservation(reservation.ActiveReservation as string)
      if (r !== undefined) {
        setReservationDetails(r)
      }
    }
  }, [reservation.ActiveReservation, reservation.Reservations])

  const handleSubmit = (feedback_score: number) => {
    if (reservationDetails) {
      dispatch(
        setLoadingOverlay({ show: true, message: "Processing your feedback!", seconds: 10 }),
      )
      post({
        url: "reservation/feedback/update",
        params: {
          reservationId: reservationDetails.ReservationId,
          feedbackScore: feedback_score,
        },
      })
        .then((response) => {
          if (response.data && response.data.Success) {
            showSnackbar(response.data.Message, "success", "top-center")
            dispatch(clearLoadingOverlay())
            navigate('/kiosk/check-out/update-newsletter')
          } else {
            showSnackbar(response.data.Message, "error", "top-center")
            dispatch(clearLoadingOverlay())
          }
        })
        .catch((error) => {
          dispatch(clearLoadingOverlay())
          showSnackbar(error.response?.data.message, "error", "top-center")
        })
    }
  }

  return (
    <div className="flex justify-center items-center flex-col h-screen">
      <div className="flex-grow flex justify-center items-center flex-col w-full">
        <div className="text-h1 text-center">
          Thank you for staying with us!
          <br />
          <span className="text-h2">
            Would you recommend CityHub <br />to your friends?
          </span>
        </div>
        <div className="mt-12 w-3/4 flex flex-col items-center gap-4">
          <HandThumbUp className="w-12 h-12 mb-4" />
          <NumberTile text="10" onClick={() => handleSubmit(10)} />
          <NumberTile text="9" onClick={() => handleSubmit(9)} />
          <NumberTile text="8" onClick={() => handleSubmit(8)} />
          <NumberTile text="7" onClick={() => handleSubmit(7)} />
          <NumberTile text="6" onClick={() => handleSubmit(6)} />
          <NumberTile text="5" onClick={() => handleSubmit(5)} />
          <NumberTile text="4" onClick={() => handleSubmit(4)} />
          <NumberTile text="3" onClick={() => handleSubmit(3)} />
          <NumberTile text="2" onClick={() => handleSubmit(2)} />
          <NumberTile text="1" onClick={() => handleSubmit(1)} />
          <HandThumbDown className="w-12 h-12 mt-4" />
        </div>
      </div>
    </div>
  )
}
