import { useDispatch, useSelector } from "react-redux"
import { InputField } from "../../../components/form/InputField"
import { FormEvent, useEffect, useState } from "react"
import Button from "../../../components/button/Button"
import ArrowSmall from "../../../components/svg/ArrowSmall"
import {
  selectActiveReservation,
  setReservations,
} from "../../../features/reservation/reservationSlice"
import { post } from "../../../helpers/api"
import { useNavigate, useParams } from "react-router-dom"
import Loader from "../../../components/svg/Loader"
import { showSnackbar } from "../../../helpers/notification"
import {
  clearLoadingOverlay,
  setLoadingOverlay,
} from "../../../features/application/appSlice"
import { RadioList } from "../../../components/form/RadioList"

export default function CustomerForm() {
  let { index } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const activeReservation = useSelector(selectActiveReservation)
  const [loading, setLoading] = useState<boolean>(false)
  const [customerIndex, setCustomerIndex] = useState<number>(0)
  const [customer, setCustomer] = useState({
    customerId: "",
    firstName: "",
    lastName: "",
    email: "",
    sex: "",
  })
  const [errorMessage, setErrorMessage] = useState({
    firstName: "",
    lastName: "",
    email: "",
    sex: "",
  })

  // Set customer index. Should be a number
  useEffect(() => {
    if (index !== undefined) {
      resetCustomer()
      setCustomerIndex(parseInt(index))
    }
  }, [index])

  // Set the customer data when customerIndex changes
  useEffect(() => {
    if (activeReservation?.Customers[customerIndex] !== undefined) {
      setCustomer((customer) => ({
        ...customer,
        ...{
          customerId: activeReservation.Customers[customerIndex].CustomerId,
          firstName: activeReservation.Customers[customerIndex].FirstName,
          lastName: activeReservation.Customers[customerIndex].LastName,
          email: activeReservation.Customers[customerIndex].Email,
          sex: activeReservation.Customers[customerIndex].Sex,
        },
      }))
    }
  }, [customerIndex])

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    updateCustomer()
  }

  const updateCustomer = () => {
    // Call API to save customer
    if (activeReservation) {
      setLoading(true)
      dispatch(
        setLoadingOverlay({
          show: true,
          message: "Saving your details",
          seconds: 5,
        }),
      )
      post({
        url: "customer/update",
        params: {
          ...customer,
          ...{ reservationId: activeReservation?.ReservationId as string },
        },
      })
        .then((response) => {
          if (response.data && response.data.Success) {
            showSnackbar(response.data.Message, "success", "top-center")
            dispatch(setReservations(response.data.Reservations))
            goToNextPage(false)
            setLoading(false)
            dispatch(clearLoadingOverlay())
          } else {
            showSnackbar(response.data.Message, "error", "top-center")
            setLoading(false)
            dispatch(clearLoadingOverlay())
          }
        })
        .catch((error) => {
          dispatch(clearLoadingOverlay())
          showSnackbar(error.response?.data.message, "error", "top-center")
          setErrorMessage(error.response?.data.errors)
          setLoading(false)
        })
    }
  }

  const goToNextPage = (skip: boolean) => {
    if (activeReservation) {
      // Go to next customer
      if (activeReservation.PersonCount > customerIndex + 1 && skip === false) {
        navigate(`/kiosk/check-in/customer-form/${customerIndex + 1}`)
      } else {
        if (activeReservation.PreAuth) {
          selectResource()
        } else {
          navigate(`/kiosk/check-in/consent`)
        }
      }
    }
  }

  /**
   * Select resource, room or other
   */
  const selectResource = () => {
    if (activeReservation?.ResourceCategoryName === "Room") {
      navigate("/kiosk/check-in/select-resource")
    } else {
      navigate("/kiosk/check-in/select-resource-video")
    }
  }

  const setCustomerValues = (name: string, value: string | number) => {
    setCustomer({ ...customer, [name]: value })
    setErrorMessage({ ...errorMessage, [name]: "" })
  }

  const resetCustomer = () => {
    setCustomer((customer) => ({
      ...customer,
      ...{
        customerId: "",
        firstName: "",
        lastName: "",
        email: "",
        sex: "",
      },
    }))
    setErrorMessage((error) => ({
      ...error,
      ...{
        firstName: "",
        lastName: "",
        email: "",
        sex: "",
      },
    }))
  }

  return (
    <div className="flex-grow flex justify-center items-center flex-col w-full">
      <form
        onSubmit={(e) => {
          handleFormSubmit(e)
        }}
        className="w-full"
      >
        <div className="text-h1 text-center">
          Traveller {customerIndex + 1},
          <br /> Please update your details.
        </div>
        <div className="mt-6 w-full space-y-4">
          <RadioList
            name="sex"
            label="Sex"
            disabled={loading}
            defaultValue={customer.sex}
            value={customer.sex === null ? "NULL" : customer.sex}
            options={{ "Mr.": "Male", "Ms.": "Female", "Mx.": "NULL" }}
            errorMessage={errorMessage.sex ?? ""}
            onChange={(e) => {
              setCustomerValues("sex", e.target.value)
            }}
          />

          <InputField
            type="text"
            name="firstName"
            label="First name"
            placeholder="First name"
            disabled={loading}
            defaultValue={customer.firstName}
            value={customer.firstName}
            errorMessage={errorMessage.firstName}
            onChange={(e) => {
              setCustomerValues("firstName", e.target.value)
            }}
          />

          <InputField
            type="text"
            name="lastName"
            label="Last name"
            placeholder="Last name"
            disabled={loading}
            defaultValue={customer.lastName}
            value={customer.lastName}
            errorMessage={errorMessage.lastName}
            onChange={(e) => {
              setCustomerValues("lastName", e.target.value)
            }}
          />

          <InputField
            type="text"
            name="email"
            label="Email address"
            placeholder="Email address"
            disabled={loading}
            defaultValue={customer.email}
            value={customer.email}
            errorMessage={errorMessage.email}
            onChange={(e) => {
              setCustomerValues("email", e.target.value)
            }}
          />
        </div>
        <div className="mt-12">
          <Button
            text={`Save${
              activeReservation?.PersonCount &&
              customerIndex + 1 < activeReservation.PersonCount
                ? " and check next traveller"
                : ""
            }`}
            suffix={loading ? <Loader /> : <ArrowSmall />}
            type="submit"
          />
          {customerIndex > 0 && (
            <div className="mt-6">
              <Button
                type="button"
                text={`My roommate arrives later / I am alone.`}
                style="secondary"
                onClick={() => goToNextPage(true)}
                suffix={loading ? <Loader /> : <ArrowSmall />}
              />
            </div>
          )}
        </div>
      </form>
    </div>
  )
}
