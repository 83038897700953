import { useSelector } from "react-redux";
import { selectMapScreen } from "../../features/mapScreen/mapScreenSlice";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import ChevronDown from "../svg/ChevronDown";
import XMark from "../svg/XMark";
import { panTo, selectPlace } from "../../helpers/mapScreen";
import { AddToMyPlaces } from "./AddToMyPlaces";

export default function CuratedPlaces() {
	const mapScreen = useSelector(selectMapScreen);
	const [show, setShow] = useState<boolean>(true);

	useEffect(() => {
		if (mapScreen.State === "CityHost") {
			setShow(true);
		} else {
			setShow(false);
		}
	}, [mapScreen.PlacesState]);

	return (
		<>
			{mapScreen.CuratedPlaces.length > 0 && (
				<motion.div
					variants={{
						show: { x: 0 },
						hide: { x: "100%" },
					}}
					animate={show ? "show" : "hide"}
					className="curated absolute top-4 bottom-4 z-10 right-4 w-1/3 flex justify-center items-center"
				>
					<div className="w-full h-full rounded-md  bg-cityhub-5 p-4">
						<div className="w-full h-full overflow-scroll flex flex-col space-y-4">
							{mapScreen.CuratedPlaces.map((place, index) => (
								<div
									className="flex-shrink-0 relative rounded-md flex flex-col justify-between items-center cursor-pointer drop-shadow-md"
									key={index}
									onClick={() => {
										panTo(place);
										selectPlace(place);
									}}
								>
									{place.Photo !== null && (
										<img
											src={place.Photo}
											className="w-full object-cover rounded-t-md aspect-[4/2]"
										/>
									)}

									<div className="absolute top-2 left-2 flex">
										<img
											src={place.CityHost?.ProfileImage}
											className="w-8 h-8 rounded-full object-cover ring ring-white"
										/>
										<span className="ml-4 text-white drop-shadow-md">
											Curated by {place.CityHost?.Name}
										</span>
									</div>

									<div className="absolut bottom-0 w-full p-2 bg-cityhub-500 flex flex-col rounded-b-md">
										<div className="flex justify-between items-center">
											<div className="flex flex-col">
												<span className="text-h4">{place.Name}</span>
												<p className="">{place?.Description}</p>
											</div>
											{mapScreen.ChatRoomDocId != "" && (
												<AddToMyPlaces place={place} />
											)}
										</div>
									</div>
								</div>
							))}
						</div>
					</div>

					<motion.div
						variants={{
							show: { opacity: 0 },
							hide: { opacity: 1 },
						}}
						animate={show ? "show" : "hide"}
						className="absolute top-[1/2] -left-8 bg-black p-2 rounded-full cursor-pointer"
						onClick={() => setShow(!show)}
					>
						<div className="rotate-90">
							<ChevronDown className="w-6 h-6 text-white translate-y-[2px]" />
						</div>
					</motion.div>

					<motion.div
						variants={{
							show: { opacity: 1 },
							hide: { opacity: 0 },
						}}
						animate={show ? "show" : "hide"}
						className="absolute -top-1 -right-1 bg-black p-2 rounded-full cursor-pointer"
						onClick={() => setShow(!show)}
					>
						<XMark className="w-6 h-6 text-white" />
					</motion.div>
				</motion.div>
			)}
		</>
	);
}
