import { IOpenBills } from "../../features/reservation/reservationInterface"

interface IOpenBillsCard {
  openBills: IOpenBills
}

export default function OpenBillsCard({ openBills }: IOpenBillsCard) {

  return (
    <>
      {(openBills.OrderItems.length > 0 ||
        openBills.PaymentItems.length > 0) && (
        <>
          <div className="w-full bg-white bg-opacity-75 rounded-t-xl p-4 max-h-96 overflow-y-auto">
            {openBills.OrderItems.length > 0 && (
              <>
                <div className="text-gray-900 text-h4 mb-4">Ordered items</div>
                {openBills.OrderItems.map((item, index) => (
                  <div className="grid grid-cols-4 gap-y-4" key={index}>
                    <span className="col-start-1 col-span-3 text-gray-600">
                      {item.Name}
                    </span>
                    <span className="text-right">
                      {item.AmountFormatted.Value}
                    </span>
                  </div>
                ))}
              </>
            )}
            {openBills.PaymentItems.length > 0 && (
              <>
                <div className="text-gray-900 text-h4 my-4">Payments</div>
                {openBills.PaymentItems.map((item, index) => (
                  <div className="grid grid-cols-4 gap-y-4" key={index}>
                    <span className="col-start-1 col-span-3 text-gray-600">
                      {item.Name}
                    </span>
                    <span className="text-right">
                      {item.AmountFormatted.Value}
                    </span>
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="w-full bg-white bg-opacity-75 border-t-4 border-purple-300 rounded-b-xl p-4 grid grid-cols-2 gap-y-4 text-black">
            <span>Total</span>
            <span className="text-right">{openBills.TotalFormatted.Value}</span>
          </div>
        </>
      )}
    </>
  )
}
