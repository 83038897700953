import { Link } from "react-router-dom"

type INumberTileProps = {
  link?: string
  icon?: JSX.Element
  text: string
  onClick?: () => void
}

export default function NumberTile(props: INumberTileProps) {
  const { link = "", icon, text, onClick = undefined } = props

  return (
    <Link
      to=""
      className="bg-white text-black w-12 h-12 rounded-md flex justify-center items-center"
      onClick={onClick}
    >
      <span className="text-3xl text-center leading-none">{text}</span>
    </Link>
  )
}
