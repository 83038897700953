import { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"

type IButtonProps = {
  link?: string
  prefix?: JSX.Element
  suffix?: JSX.Element
  text: string
  target?: "_blank" | "_self"
  type?: "link" | "submit" | "button"
  style?:
    | "primary"
    | "primaryDisabled"
    | "secondary"
    | "secondaryDisabled"
    | "error"
    | "info"
    | "contrast"
    | "contrastRight"
    | "plain"
    | "plainBorder"
  size?: "small" | "medium" | "large"
  loading?: boolean
  classes?: string
  onClick?: () => void
  onClicks?: () => void
  pressSeconds?: number
}

export default function Button(props: IButtonProps) {
  const [cursor, setCursor] = useState<string>("")
  const [disabled, setDisabled] = useState<string>("")
  const countInterval = useRef<ReturnType<typeof setInterval> | null>(null)
  const {
    link = "",
    prefix = null,
    suffix = null,
    text,
    type = "link",
    style = "primary",
    size = "large",
    loading = false,
    onClick = undefined,
    pressSeconds = 0,
    target = "_self",
    classes = "",
  } = props

  const styles: { [key: string]: string } = {
    primary: "flex justify-between items-center rounded-md bg-black text-white",
    primaryDisabled:
      "flex justify-between items-center rounded-md bg-black text-white opacity-25 pointer-events-none",
    secondary:
      "flex justify-between items-center rounded-md border border-black text-black",
    secondaryDisabled:
      "flex justify-between items-center rounded-md border border-black text-black opacity-25 pointer-events-none",
    error:
      "flex justify-between items-center rounded-md bg-red-600 text-white",
    contrast:
      "flex justify-between items-center rounded-md bg-white text-black",
    contrastRight:
      "flex justify-end items-center rounded-md bg-white text-black",
    info: "flex justify-between items-center rounded-md bg-gray-600 text-white",
    secondaryAlt: "flex justify-between items-center text-black text-center",
    secondaryAltRight: "flex justify-end items-center text-black",
    plain: "flex justify-center items-center text-black",
    plainBorder:
      "flex justify-center items-center text-black border-b border-black",
  }
  const sizes: { [key: string]: string } = {
    small: " px-4 py-3 text-h5 ",
    medium: " px-4 py-3 w-3/4 text-h4 ",
    large: " px-4 py-3 w-full text-h4 ",
  }

  useEffect(() => {
    if (link === "" && onClick === undefined) {
      setCursor(" cursor-default")
    }
  }, [])

  useEffect(() => {
    if (loading) {
      setDisabled(" opacity-25 pointer-events-none")
    } else {
      setDisabled("")
    }
  }, [loading])

  const click = () => {
    if(pressSeconds === 0){
      if (onClick!== undefined) {
        onClick()
      }
    }
  }

  const startPress = () => {
    if (pressSeconds > 0) {
      let seconds: number = 0;
      countInterval.current = setInterval(() => {
        if (pressSeconds === seconds) {
          if (countInterval.current) clearInterval(countInterval.current);
          if (onClick!== undefined) {
            onClick()
          }
        }
        seconds = seconds + 1
      }, 1000);
    }
  }

  const clearPress = () => {
    if (pressSeconds > 0) {
      if (countInterval.current) clearInterval(countInterval.current);
    }
  }

  return (
    <>
      {type === "link" && (
        <Link
          to={link}
          className={sizes[size] + styles[style] + classes + cursor + disabled}
          onClick={onClick}
          target={target}
          rel={target === "_blank" ? "noopener noreferrer" : ""}
        >
          {prefix && <div className="mr-2">{prefix}</div>}
          <span className="leading-none">{text}</span>
          {suffix && <div className="ml-2">{suffix}</div>}
        </Link>
      )}

      {(type === "submit" || type === "button") && (
        <button
          className={
            `cursor-pointer ` +
            sizes[size] +
            styles[style] +
            classes +
            cursor +
            disabled
          }
          onClick={click}
          onMouseDown={startPress}
          onTouchStart={startPress}
          onMouseUp={clearPress}
          onMouseLeave={clearPress}
          onTouchEnd={clearPress}
          type={type}
        >
          {prefix && <div className="mr-2">{prefix}</div>}
          <span className={`leading-none`}>{text}</span>
          {suffix && <div className="ml-2">{suffix}</div>}
        </button>
      )}
    </>
  )
}
