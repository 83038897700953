import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  clearLoadingOverlay,
  selectLoadingOverlay,
} from "../features/application/appSlice"
import LoadingIcon from "./svg/Loading"
import moment from "moment"

export function LoadingOverlay() {
  const dispatch = useDispatch()
  const [hidden, setHidden] = useState<boolean>(true)
  const [show, setShow] = useState<boolean>(false)
  const [message, setMessage] = useState<string>()
  const loadingOverlay = useSelector(selectLoadingOverlay)
  const showTime: any = useRef(null)
  const mount = useRef(true)

  // Make sure the overlay stays at least the given seconds or when is closed
  useEffect(() => {
    // Do not run on mount
    if (mount.current) {
      mount.current = false
    } else {
      if (loadingOverlay.show) {
        // Reset interval
        clearInterval(showTime.current)
        showOverlay()

        // Set interval to check if the overlay should be hidden
        showTime.current = setInterval(() => {
          if (loadingOverlay.timestamp <= moment().valueOf()) {
            hideOverlay()
          }
        }, 1000)
      } else {
        hideOverlay()
      }
    }
  }, [loadingOverlay.timestamp, loadingOverlay.show])

  const showOverlay = () => {
    setMessage(loadingOverlay.message)
    setHidden(false)
    setShow(true)
  }

  const hideOverlay = () => {
    setShow(false)
    setTimeout(() => {
      setHidden(true)
    }, 300)
    setMessage("")
    clearInterval(showTime.current)
    if (loadingOverlay.timestamp != 0) {
      dispatch(clearLoadingOverlay())
    }
  }

  return (
    <div
      className={`bg-cityhub-green fixed z-40 inset-0 flex justify-center items-center ${
        hidden ? "hidden" : ""
      } ${
        show ? "opacity-100" : "opacity-0"
      } transition-opacity duration-300 bg-black`}
    >
      <div className={`text-center py-4 px-24 text-black`}>
        <div className="flex justify-center items-center">
          <div className="bg-white bg-opacity-75 p-8 rounded-md flex flex-col items-center">
            <LoadingIcon className="w-12 h-12 text-cityhub-green animate-loading" />
            {message !== "" && (
              <div className="mt-6">{message}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
