import axios from "axios"
import { store } from "../app/store"

interface IGet {
  url: string
  params: { [key: string]: number | string }
}

interface IPost {
  url: string
  params: { [key: string]: number | string }
}

interface IRequest {
  method: "get" | "post"
  url: string
  data: { [key: string]: number | string }
}

const http = axios.create({
  baseURL: process.env.VITE_CHAPI_URL,
})

export function get({ url, params }: IGet) {
  const { config } = store.getState()
  return http.get(url, { params: params, headers: { "x-api-key": config.ApiKey, "x-api-uuid": config.ApiUuid } })
}

export function post({ url, params }: IPost) {
  const { config } = store.getState()
  return http.post(
    url,
    params,
    { headers: { "x-api-key": config.ApiKey, "x-api-uuid": config.ApiUuid } },
  )
}

export function request({ method, url, data }: IRequest) {
  http({
    method,
    url,
    data,
  })
}
