import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { showSnackbar } from "../../../helpers/notification"
import {
  selectActiveReservation,
  setReservations,
} from "../../../features/reservation/reservationSlice"
import { selectConfig } from "../../../features/config/configSlice"
import { post } from "../../../helpers/api"
import { formatStudlyCase } from "../../../helpers/base"
import {
  clearLoadingOverlay,
  setLoadingOverlay,
} from "../../../features/application/appSlice"
import axios from "axios"
import ArrowSmall from "../../../components/svg/ArrowSmall"
import Button from "../../../components/button/Button"

export default function ScanWristband() {
  let { index } = useParams()
  const config = useSelector(selectConfig)
  const activeReservation = useSelector(selectActiveReservation)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [failed, setFailed] = useState(false)

  // Init wristband
  useEffect(() => {
    if (index !== undefined) {
      setTimeout(() => {
        registerWristband()
      }, 1000)
    }
  }, [index])

  const registerWristband = () => {
    setFailed(false)
    if (index !== undefined) {
      const customerIndex = parseInt(index)
      const params = {
        Encoder: config.Kiosk?.EncoderNumber,
        SecondCard: customerIndex === 0 ? false : true,
        Hub: activeReservation?.AssignedResourceNumber,
        StartUtc: activeReservation?.StartAt,
        EndUtc: activeReservation?.EndAt,
        ReservationId: activeReservation?.ReservationId,
        CustomerId: activeReservation?.Customers[customerIndex].CustomerId,
        LastName: activeReservation?.Customers[customerIndex].LastName,
      }

      // Testing token
      if (config.Property?.LockApiUrl.includes("chapi")) {
        post({
          url: "customer/token/make",
          params: {},
        })
          .then((response) => {
            handleSubmit(response.data.Token, params)
          })
          .catch((error) => {
            console.log(error)
            showSnackbar(
              "Something went wrong, please try again.",
              "error",
              "top-center",
            )
            setFailed(true)
          })
      }

      // Production token
      else {
        axios
          .post(`${config.Property?.LockApiUrl}/makeToken`, params)
          .then((response) => {
            handleSubmit(response.data.result.token, params)
          })
          .catch((error) => {
            console.log(error)
            showSnackbar(
              "Something went wrong, please try again.",
              "error",
              "top-center",
            )
            setFailed(true)
          })
      }
    }
  }

  /**
   * Handles submitting the wristband scan
   * Send token to api
   * Check if there is another guest
   * Navigate to select scene
   */
  const handleSubmit = (token: number, params?: any) => {
    if (index !== undefined) {
      const customerIndex = parseInt(index)
      dispatch(
        setLoadingOverlay({
          show: true,
          message: "Connecting your wristband.",
        }),
      )

      const customer = formatStudlyCase(
        activeReservation?.Customers[customerIndex] as object,
      )

      post({
        url: "customer/token/update",
        params: {
          token: token,
          customerId: customer.customerId,
          reservationId: activeReservation?.ReservationId as string,
          params: params,
        },
      })
        .then((response) => {
          if (response.data && response.data.Success) {
            showSnackbar(response.data.Message, "success", "top-center")
            dispatch(setReservations(response.data.Reservations))

            /**
             * Get next wristband if there is another guest
             */
            if (
              activeReservation &&
              customerIndex === activeReservation.Customers.length - 1
            ) {
              navigate("/kiosk/check-in/confirmation")
            } else {
              navigate(`/kiosk/check-in/scan-wristband/${customerIndex + 1}`)
            }
          }
        })
        .catch((error) => {
          dispatch(clearLoadingOverlay())
          showSnackbar(error.response?.data.message, "error", "top-center")
          setFailed(true)
        })
    }
  }

  return (
    <div className="flex-grow flex justify-center items-center flex-col w-3/4">
      <div className="grid grid-cols-1 gap-12">
        <div className="text-h1 text-center">
          {index !== undefined &&
          activeReservation?.Customers[parseInt(index)] ? (
            <div>
              {activeReservation?.Customers[parseInt(index)].FirstName},
            </div>
          ) : (
            ""
          )}
          Grab your wristband and scan it here.
        </div>
        {config.Property?.VideoScanWristbandUrl && (
          <video autoPlay muted loop>
            <source
              src={config.Property?.VideoScanWristbandUrl}
              type="video/mp4"
            />
          </video>
        )}
        {failed && (
          <Button
            type="button"
            onClick={() => registerWristband()}
            text="Please try again"
            suffix={<ArrowSmall />}
          />
        )}
      </div>
    </div>
  )
}
