import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { IChatRoomState } from "./chatRoomInterface"

const initialState: IChatRoomState = {
  Id: '',
  HotelCode: '',
  Status: 'all',
  Messages: [],
  Owner: {
    FirstName: '',
    ProfilePicture: '',
    Uuid: '',
  },
  NotificationToken: '',
}

export const chatRoomSlice = createSlice({
  name: "chatRoom",
  initialState,
  reducers: {
    
    SetChatRoom: (
      state,
      action: PayloadAction<IChatRoomState>,
    ) => {
      state.Id = action.payload.Id
      state.Messages = action.payload.Messages
      state.Owner = action.payload.Owner
      state.NotificationToken = action.payload.NotificationToken
    },
  },
})

export const {
  SetChatRoom
} = chatRoomSlice.actions

// Selectors
export const selectChatRoom = (state: RootState) => state.chatRoom

export default chatRoomSlice.reducer
