import { useDispatch, useSelector } from "react-redux";
import { selectConfig } from "../../features/config/configSlice";
import { showSnackbar } from "../../helpers/notification";
import { get } from "../../helpers/api";
import { useEffect } from "react";
import { SearchPlaces } from "../../components/mapScreen/SearchPlaces";
import { MapMap } from "../../components/mapScreen/MapMap";
import {
	MsSetChatRoomDocId,
	MsSetCityHost,
	MsSetCuratedPlaces,
	MsSetHotel,
	MsSetHotelDocId,
	MsSetHotels,
	selectMapScreen,
} from "../../features/mapScreen/mapScreenSlice";
import {
	panTo,
	switchMapScreen,
	toggleConnect,
} from "../../helpers/mapScreen";
import { SelectedPlace } from "../../components/mapScreen/SelectedPlace";
import { MapScanWristband } from "../../components/mapScreen/MapScanWristband";
import { MyPlaces } from "../../components/mapScreen/MyPlaces";
import { doc, onSnapshot } from "firebase/firestore";
import {
	fireDb,
	getChatroomByBookingId,
	getChatroomByDocId,
	getHotels,
} from "../../helpers/firebase";
import { SetChatRoom } from "../../features/chatRoom/chatRoomSlice";
import { formatChatroom } from "../../helpers/chatRoom";
import CityHost from "../../components/mapScreen/CityHost";
import CuratedPlaces from "../../components/mapScreen/CuratedPlaces";
import { MapOverlay } from "../../components/mapScreen/MapOverlay";
import axios from "axios";
import ArrowLeftRight from "../../components/svg/ArrowsLeftRight";
import { IChatRoom } from "../../interfaces/chatRoomInterface";

export default function HomeMapScreen() {
	const dispatch = useDispatch();
	const config = useSelector(selectConfig);
	const mapScreen = useSelector(selectMapScreen);

	// useEffect(() => {
	// 	var channel = window.Echo.channel(
	// 		"mapscreen." + config.Property?.Abbreviation,
	// 	);
	// 	channel.listen(".init", function (data: any) {
	// 		console.log(JSON.stringify(data));
	// 	});
	// }, [config]);

	/**
	 * Get last login from the mobile api and get the host content
	 */
	useEffect(() => {
		getLastLogin();
		const intervalId = setInterval(getLastLogin, 300000);
		return () => clearInterval(intervalId);
	}, []);

	/**
	 * Get last login from the mobile api.
	 * The hosts login in the console so we know who is the last host.
	 */
	const getLastLogin = () => {
		axios
			.get(`https://mobile-api.cityhub.com/api/v1/host/current?HotelCode=${config.Property?.Abbreviation}`)
			.then((response) => {
				init(response.data.result.id);
			})
			.catch((error) => {
				showSnackbar("Please refresh the page", "error", "top-center");
			});
	};

	/**
	 * Init map,
	 * Get hotels
	 * Get places
	 * Set center to hotel
	 */
	const init = (mobile_api_id: string) => {
		get({ url: `map-screen/init`, params: { mobileApiId: mobile_api_id } })
			.then((response) => {
				if (response.data && response.data.Success) {
					dispatch(MsSetHotel(response.data.Hotel));
					dispatch(MsSetHotels(response.data.Hotels));
					dispatch(MsSetCuratedPlaces(response.data.CuratedPlaces));
					dispatch(MsSetCityHost(response.data.CityHost));
				} else {
					showSnackbar(response.data.Message, "error", "bottom-center");
				}
			})
			.catch((error) => {
				showSnackbar(error.response?.Message, "error", "bottom-center");
			});
	};

	useEffect(() => {
		if (mapScreen.Hotel !== null) {
      if(mapScreen.Position.Lat === 0.000001 && mapScreen.Position.Lng === 0.000001) {
        panTo(mapScreen.Hotel);
      }
		}
	}, [mapScreen.Hotel]);

	useEffect(() => {
		const hotelsDoc = getHotels();
		hotelsDoc.then((docs) => {
			if (docs.size) {
				docs.forEach((doc) => {
					let hotel = doc.data();
					hotel.id = doc.id;
					if (hotel.hotelCode === config.Property?.Abbreviation) {
						dispatch(MsSetHotelDocId(doc.id));
					}
				});
			}
		});
	}, [config.Property]);

	useEffect(() => {
		if (mapScreen.HotelDocId !== undefined && mapScreen.HotelDocId !== "") {
			const unsuscribe = onSnapshot(
				doc(fireDb, "hotels", mapScreen.HotelDocId),
				(doc: any) => {
					const data = doc.data();

					toggleConnect(data.placesReservationId);
				},
			);

			return () => {
				unsuscribe();
			};
		}
	}, [mapScreen.HotelDocId]);

	useEffect(() => {
		// 083EA42C-ADF4-41AE-AEF9-4B9E57824B6E
		if (
			mapScreen.ChatReservationId !== undefined &&
			mapScreen.ChatReservationId !== ""
		) {
			const chatRoomArray: IChatRoom[] = [];
			const getchatrooms = getChatroomByBookingId(mapScreen.ChatReservationId);

			getchatrooms.then((chatRoomDocs) => {
				if (chatRoomDocs.size) {
					chatRoomDocs.forEach((doc) => {
						let room = doc.data();
						room.id = doc.id;
						chatRoomArray.push(room as IChatRoom);
					});
					dispatch(MsSetChatRoomDocId(chatRoomArray[0].id));
          getChatroomByDocId(chatRoomArray[0].id).then((room) => {
          // getChatroomByDocId('083EA42C-ADF4-41AE-AEF9-4B9E57824B6E').then((room) => {
            if (room !== null && room !== undefined) {
              dispatch(SetChatRoom(formatChatroom(room)));
            } else {
              showSnackbar(
                "Could not find the chat room.",
                "error",
                "bottom-center",
              );
            }
          });
				} else {
					dispatch(MsSetChatRoomDocId(""));
					showSnackbar(
						"Could not find the guest. Maybe they don't have their booking connected to the app.",
						"error",
						"bottom-center",
					);
				}
			});
		} else {
      dispatch(MsSetChatRoomDocId(""));
    }
	}, [mapScreen.ChatReservationId]);

	// const connect = () => {
	// 	updateHotelPlacesReservationId(
	// 		mapScreen.HotelDocId,
	// 		"083EA42C-ADF4-41AE-AEF9-4B9E57824B6E",
	// 	);
	// };

	return (
		<div className="fixed inset-0 bg-cityhub-500">
			<MapMap />

			{mapScreen.State === "Map" && (
				<div className="absolute top-4 left-4 z-10 w-[400px] flex flex-col space-y-4">
					<MyPlaces />
					<SelectedPlace />
				</div>
			)}

			<CityHost />
			<MapScanWristband />
			<MapOverlay />
			<SearchPlaces />
			<CuratedPlaces />

			<div
				className="absolute bottom-2 right-2 z-20 flex justify-start bg-black p-2 rounded-md text-white text-sm flex items-center cursor-pointer"
				onClick={() => {
					switchMapScreen();
				}}
			>
				<ArrowLeftRight />
			</div>
		</div>
	);
}
