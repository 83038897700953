import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { store } from "./app/store"
import { PersistGate } from "redux-persist/integration/react"
import { persistStore } from "redux-persist"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import * as Sentry from "@sentry/react"

import Echo from "laravel-echo"
import Pusher from "pusher-js"

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://da2e80429422404f64baada649fda6a1@o160706.ingest.us.sentry.io/4506897002004480",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /^https:\/\/kiosk-staging.cityhub.com/,
      /^http:\/\/kiosk.cityhub.com/,
      /^https:\/\/kiosk.cityhub.com/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}
let persistor = persistStore(store)

/**
 * Set websocket connection to chapi
 * Not used at the moment, but will be used for refreshing mapscreen
 */
// window.Pusher = Pusher

// window.Echo = new Echo({
//   broadcaster: "pusher",
//   key: "bb30f9c70dee9a027d2f",
//   cluster: "eu",
//   forceTLS: true,
// })

// declare global {
//   interface Window {
//     Pusher: any
//     Echo: Echo
//   }
// }

ReactDOM.createRoot(document.getElementById("root")!).render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  // </React.StrictMode>,
)
