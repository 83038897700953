import { useEffect, useRef, useState } from "react"
import Button from "../../../components/button/Button"
import { ucFirst } from "../../../helpers/base"
import { useDispatch, useSelector } from "react-redux"
import {
  selectActiveReservation,
  selectReservation,
  setReservations,
} from "../../../features/reservation/reservationSlice"
import {
  clearLoadingOverlay,
  setLoadingOverlay,
} from "../../../features/application/appSlice"
import { useNavigate } from "react-router-dom"
import { IResources } from "../../../features/reservation/reservationInterface"
import { showSnackbar } from "../../../helpers/notification"
import { get, post } from "../../../helpers/api"
import Heart from "../../../components/svg/Heart"
import MusicalNote from "../../../components/svg/MusicalNote"
import Sun from "../../../components/svg/Sun"
import Nature from "../../../components/svg/Nature"
import ArrowSmall from "../../../components/svg/ArrowSmall"

export default function SelectResourceVideo() {
  const [retry, setRetry] = useState<boolean>(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const reservation = useSelector(selectReservation)
  const activeReservation = useSelector(selectActiveReservation)
  const [resources, setResources] = useState<IResources>()
  const [errorMessage, setErrorMessage] = useState("")

  const [showButtons, setShowButtons] = useState(false)
  const [sceneButtons, setSceneButtons] = useState(false)
  const [sceneNumber, setSceneNumber] = useState<number | null>(null)
  const [hubType, setHubType] = useState<string | null>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const countInterval = useRef<ReturnType<typeof setInterval> | null>(null)
  const scenes = [
    {
      type: "top",
      name: "Sunset boulevard",
      number: 4,
      start: 48,
      stop: 52,
      loop: true,
      suffix: <Sun className="w-6 h-6" />,
    },
    {
      type: "top",
      name: "Let's get groovy",
      number: 5,
      start: 54,
      stop: 56,
      loop: true,
      suffix: <MusicalNote className="w-6 h-6" />,
    },
    {
      type: "top",
      name: "We are love",
      number: 2,
      start: 60,
      stop: 62,
      loop: true,
      suffix: <Heart className="w-6 h-6" />,
    },
    {
      type: "top",
      name: "Back to nature",
      number: 3,
      start: 65,
      stop: 67,
      loop: true,
      suffix: <Nature className="w-6 h-6" />,
    },
    {
      type: "bottom",
      name: "Sunset boulevard",
      number: 4,
      start: 13,
      stop: 17,
      loop: true,
      suffix: <Sun className="w-6 h-6" />,
    },
    {
      type: "bottom",
      name: "Let's get groovy",
      number: 5,
      start: 18,
      stop: 21,
      loop: true,
      suffix: <MusicalNote className="w-6 h-6" />,
    },
    {
      type: "bottom",
      name: "We are love",
      number: 2,
      start: 23,
      stop: 27,
      loop: true,
      suffix: <Heart className="w-6 h-6" />,
    },
    {
      type: "bottom",
      name: "Back to nature",
      number: 3,
      start: 32,
      stop: 34,
      loop: true,
      suffix: <Nature className="w-6 h-6" />,
    },
  ]

  useEffect(() => {
    getAvailableResources()
  }, [reservation.ActiveReservation])

  const getAvailableResources = (refreshReservation: string = "true") => {
    if (activeReservation) {
      dispatch(
        setLoadingOverlay({
          show: true,
          message: "Checking rooms...",
          seconds: 5,
        }),
      )

      setRetry(false)

      get({
        url: `resource/availability`,
        params: {
          reservationId: activeReservation?.ReservationId,
          refreshReservation: refreshReservation,
        },
      })
        .then((response) => {
          if (response.data && response.data.Success) {
            showSnackbar(response.data.Message, "success", "top-center")
            setResources(response.data.Resources)

            // Only bottom is available
            if (
              (response.data.Resources.Top === undefined &&
                response.data.Resources.Bottom.Id !== null) ||
              (response.data.Resources.Top !== undefined &&
                response.data.Resources.Top.Id === null &&
                response.data.Resources.Bottom.Id !== null)
            ) {
              updateResource(
                response.data.Resources.Bottom["Id"],
                response.data.Resources.Bottom["Number"],
                response.data.Resources.Bottom["FloorNumber"],
                response.data.Resources.Bottom["Locked"],
              )
              setHubType("bottom")
              goToFrameAndPlay(0, 10, false, 2.0, () => {
                chooseScene(scenes.find((scene) => scene.type === "bottom"))
                setSceneButtons(true)
              })
            }
            // Only top is available
            else if (
              (response.data.Resources.Bottom === undefined &&
                response.data.Resources.Top.Id !== null) ||
              (response.data.Resources.Bottom !== undefined &&
                response.data.Resources.Bottom.Id === null &&
                response.data.Resources.Top.Id !== null)
            ) {
              updateResource(
                response.data.Resources.Top["Id"],
                response.data.Resources.Top["Number"],
                response.data.Resources.Top["FloorNumber"],
                response.data.Resources.Top["Locked"],
              )
              setHubType("top")
              goToFrameAndPlay(35, 46, false, 2.0, () => {
                chooseScene(scenes.find((scene) => scene.type === "top"))
                setSceneButtons(true)
              })
            }
            // Choose top or bottom
            else {
              goToFrameAndPlay(0, 3.5, false, 1.0, chooseVariant)
            }
          } else {
            /**
             * Something went wrong like, Room not ready yet
             */
            // showSnackbar(response.data.Message, "error", "top-center")
            setErrorMessage(response.data.Message)
            setRetry(true)
          }
          dispatch(clearLoadingOverlay())
        })
        .catch((error) => {
          console.log(error)

          dispatch(clearLoadingOverlay())
          showSnackbar(error.response?.Message, "error", "top-center")
        })
    }
  }

  const updateResource = (
    resourceId: string | null | undefined,
    resourceNumber: string | undefined,
    floorNumber: string | undefined,
    locked: boolean,
  ) => {
    if (locked) {
    } else if (
      activeReservation &&
      typeof resourceId === "string" &&
      typeof resourceNumber === "string" &&
      typeof floorNumber === "string"
    ) {
      post({
        url: "resource/update",
        params: {
          reservationId: activeReservation?.ReservationId,
          resourceId,
          resourceNumber,
          floorNumber,
        },
      })
        .then((response) => {
          if (response.data && response.data.Success) {
            showSnackbar(response.data.Message, "success", "top-center")
            dispatch(setReservations(response.data.Reservations))
          } else {
            showSnackbar(response.data.Message, "error", "top-center")
          }
        })
        .catch((error) => {
          showSnackbar(error.response?.data.message, "error", "top-center")
        })
    }
  }

  const updateScene = () => {
    const scene: any = scenes.find((s) => s.number === sceneNumber)

    if (activeReservation && scene !== null) {
      const name = scene.name ?? ""
      dispatch(
        setLoadingOverlay({
          show: true,
          message: "Setting your Hub in the " + name.toLowerCase() + " mood",
        }),
      )
      post({
        url: `reservation/set-scene`,
        params: {
          reservationId: activeReservation?.ReservationId,
          scene: scene.number,
        },
      })
        .then((response) => {
          dispatch(clearLoadingOverlay())
          if (response.data && response.data.Success) {
            navigate("/kiosk/check-in/scan-wristband/0")
          } else {
            dispatch(clearLoadingOverlay())
            showSnackbar(response.data.Message, "error", "top-center")
          }
        })
        .catch((error) => {
          showSnackbar(error.response?.data.message, "error", "top-center")
          dispatch(clearLoadingOverlay())
          dispatch(clearLoadingOverlay())
        })
    }
  }

  const chooseVariant = () => {
    setShowButtons(true)
  }

  const chooseScene = (scene: any) => {
    if (scene) {
      selectScene(scene.number)
      goToFrameAndPlay(scene.start, scene.stop, scene.loop)
    }
  }

  const selectScene = (number: number | null) => {
    setSceneNumber(number)
  }

  const goToFrameAndPlay = async (
    start: number,
    stop: number,
    loop: boolean = false,
    speed: number = 1.0,
    callBack: () => void = () => {},
  ) => {
    if (countInterval.current) {
      if (videoRef.current !== null) videoRef.current.pause()
      clearInterval(countInterval.current)
    }

    if (videoRef.current !== null) {
      videoRef.current.currentTime = start
      videoRef.current.playbackRate = speed
      videoRef.current.play()

      countInterval.current = setInterval(async () => {
        if (videoRef.current !== null) {
          if (videoRef.current.currentTime >= stop) {
            if (countInterval.current) clearInterval(countInterval.current)
            if (loop) {
              videoRef.current.pause()
              goToFrameAndPlay(start, stop, loop)
            } else {
              videoRef.current.pause()
              callBack()
            }
          }
        }
      }, 10)
    }
  }

  return (
    <div className="flex-grow flex flex-col justify-center items-center relative w-full">
      {retry === false && (
        <div className="fixed inset-0 flex justify-content items-center">
          <video
            ref={videoRef}
            id="video"
            src="https://cityhub-assets.s3.eu-west-1.amazonaws.com/kiosk/CityHub_Both-Hubs_v002.mp4"
            muted={true}
            playsInline={true}
            preload="auto"
          ></video>
        </div>
      )}
      {retry == true && (
        <div className="text-center">
        <div className="text-h1">Whoops, something went wrong.</div>
        <div className="text-h3">{errorMessage}</div>
          <div className="mt-12">
            <Button
              type="button"
              onClick={() => getAvailableResources("true")}
              text="Ask the host and try again"
            />
          </div>
        </div>
      )}
      {resources !== undefined && (
        <>
          <div
            className={`absolute top-60 w-full p-4 ${
              showButtons && hubType === null ? "" : "hidden"
            }`}
          >
            <div className="text-h2 text-center">
              Would you like a top
              <br />
              or bottom Hub?
            </div>
          </div>
          <div
            className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mt-6 ml-5 h-60 w-3/4 p-4 flex flex-col justify-between items-between ${
              showButtons && hubType === null ? "" : "hidden"
            }`}
          >
            <div className="w-1/2">
              <div
                className={`flex flex-col justify-between items-center p-4 rounded-md bg-white/50`}
                onClick={() => {
                  if (resources.Top !== undefined) {
                    updateResource(
                      resources.Top["Id"],
                      resources.Top["Number"],
                      resources.Top["FloorNumber"],
                      resources.Top["Locked"],
                    )
                  }
                  setShowButtons(false)
                  setHubType("top")
                  goToFrameAndPlay(44, 46, false, 1.0, () => {
                    chooseScene(scenes.find((scene) => scene.type === "top"))
                    setSceneButtons(true)
                  })
                }}
              >
                <div className="flex justify-center items-center text-black ">
                  Top Hub
                </div>
              </div>
            </div>
            <div className="ml-auto w-1/2">
              <div
                className={`flex flex-col justify-between items-center p-4 rounded-md bg-white/50`}
                onClick={() => {
                  if (resources.Bottom !== undefined) {
                    updateResource(
                      resources.Bottom["Id"],
                      resources.Bottom["Number"],
                      resources.Bottom["FloorNumber"],
                      resources.Bottom["Locked"],
                    )
                  }
                  setShowButtons(false)
                  setHubType("bottom")
                  goToFrameAndPlay(8, 10, false, 1.0, () => {
                    chooseScene(scenes.find((scene) => scene.type === "bottom"))
                    setSceneButtons(true)
                  })
                }}
              >
                <div className="flex justify-center items-center text-black ">
                  Bottom Hub
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div
        className={`absolute top-52 w-full z-4 p-4 ${
          sceneButtons && hubType !== null ? "opacity-100" : "hidden"
        }`}
      >
        <div className="text-h2 text-center">What's your mood today?</div>
      </div>
      <div
        className={`absolute bottom-1 w-full z-4 p-4 ${
          sceneButtons && hubType !== null ? "opacity-100" : "hidden"
        }`}
      >
        <div className="mt-4 grid grid-cols-2 gap-2">
          {scenes
            .filter((s) => s.type === hubType)
            .map((s, i) => {
              return (
                <div
                  className={`flex flex-col justify-between items-center p-4 rounded-md ${
                    sceneNumber == s.number ? `bg-white/25` : `border-white/0`
                  }`}
                  key={i}
                  onClick={() => {
                    selectScene(s.number)
                    goToFrameAndPlay(s.start, s.stop, s.loop, 1.0)
                  }}
                >
                  {s.suffix}
                  <div className="flex justify-center items-center text-black ">
                    {s.name ?? ""}
                  </div>
                </div>
              )
            })}
        </div>
        <div
          className={`flex justify-between items-center space-x-8 mt-12 ${
            sceneNumber !== null ? "" : "hidden"
          }`}
        >
          <Button
            type="button"
            onClick={() => {
              updateScene()
            }}
            text="Next"
            style={`primary`}
            suffix={<ArrowSmall />}
          />
        </div>
      </div>
    </div>
  )
}
