import ArrowSmall from "../svg/ArrowSmall"
import Loader from "../svg/Loader"

type ISubmitSuffix = {
  onSubmit: () => void
  loading?: boolean
}

export default function SubmitSuffix(props: ISubmitSuffix) {
  const { onSubmit, loading = false } = props

  return (
    <div
      className="absolute cursor-pointer top-0 bottom-0 right-0 bg-black text-white w-14 flex justify-center items-center rounded-tr-lg rounded-br-lg"
      onClick={() => {
        if(!loading) {
          onSubmit()
        }
      }}
    >
      {loading && <Loader />}
      {!loading && <ArrowSmall />}
     
    </div>
  )
}
