import Button from "../../../components/button/Button"
import ArrowSmall from "../../../components/svg/ArrowSmall"
import { resetReservations } from "../../../helpers/reservation"

export default function NoResourcesAvailable() {
  return (
    <div className="flex-grow flex justify-center items-center flex-col w-full">
      <div className="text-h1 text-center">
        Sorry, there are no rooms available at the moment.
      </div>
      <div className="mt-12 text-h3 text-center">
        You can grab a wristband to use a locker. <br />
        Your room will be ready soon.
      </div>
      <div className="mt-12 w-full">
        <Button
          type="button"
          onClick={() => {
            resetReservations()
          }}
          text="Close"
          suffix={<ArrowSmall />}
        />
      </div>
    </div>
  )
}
