import { SVGProps } from "react";

export default function Logo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="128"
      height="77"
      viewBox="0 0 128 77"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      {...props}
    >
      <path
        d="M73.519 20.897c-1.012 1.35-3.286 3.614-7.149 3.614-4.908 0-8.61-3.837-8.61-8.924 0-5.004 3.683-8.923 8.384-8.923 2.276 0 4.452.951 6.127 2.68l.292.3 3.835-4.185-.285-.285C73.527 2.59 70 1.167 66.182 1.167c-8.16 0-14.314 6.2-14.314 14.42 0 8.22 6.186 14.42 14.39 14.42 4.648 0 8.804-1.958 11.4-5.372l.233-.305-4.109-3.784-.263.351zm6.3-10.976h5.704v19.697H79.82V9.92zm2.852-2.635c2.022 0 3.606-1.6 3.606-3.644C86.277 1.6 84.693 0 82.67 0s-3.606 1.6-3.606 3.642c0 2.044 1.584 3.644 3.606 3.644zm10.017 13.942c0 2.756.43 4.518 1.438 5.89 1.373 1.89 3.674 2.889 6.655 2.889.988 0 2.487-.158 3.187-.413l.323-.117-.89-5.05-.407.094c-.329.074-1.236.184-1.911.184-1.01 0-1.745-.266-2.183-.787-.356-.435-.508-1.064-.508-2.106v-6.784h6.032V9.921h-6.032V4.669h-5.704V9.92h-5.09v5.107h5.09v6.2zm16.336 17.728h6.013L128 9.92h-6.051l-4.695 10.76-5.089-10.76h-6.29l8.385 17.383-5.236 11.652zM18.287 58.859H5.892V48.55H0v28.06h5.892V64.356h12.395V76.61h5.892V48.549h-5.892v10.31zM40.96 69.437c-.484.604-1.864 2.067-3.86 2.067-.577 0-1.664-.135-2.451-1.028-.418-.488-.805-1.185-.805-2.955V56.914H28.14v10.412c0 2.716.634 4.837 1.937 6.486A8.364 8.364 0 0 0 36.61 77c1.683 0 3.235-.502 4.539-1.46v1.07h5.515V56.915H40.96v12.523zm15.556-5.355c1.003-1.311 2.498-2.06 4.123-2.06 2.801 0 4.312 2.452 4.312 4.76 0 2.736-1.86 4.722-4.425 4.722a4.908 4.908 0 0 1-4.01-2.053v-5.369zm4.765-7.557c-1.8 0-3.4.457-4.765 1.361V48.16h-5.704v28.45h5.516v-1.09c1.37.983 2.944 1.48 4.689 1.48 2.77 0 5.464-1.247 7.204-3.334 1.57-1.876 2.435-4.321 2.435-6.884 0-5.752-4.119-10.257-9.375-10.257zm63.11-10.934c-1.56 0-2.829 1.31-2.829 2.919v15.444H87.37c-1.56 0-2.828 1.309-2.828 2.918 0 1.61 1.269 2.918 2.828 2.918h39.849V48.51c0-1.61-1.269-2.919-2.828-2.919zM2.917 36.501c1.56 0 2.828-1.308 2.828-2.918V18.14h34.192c1.56 0 2.828-1.309 2.828-2.918 0-1.61-1.268-2.918-2.828-2.918H.088v21.28c0 1.61 1.27 2.919 2.829 2.919z"
        fillRule="evenodd"
      />
    </svg>
  );
}
