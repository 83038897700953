import { useEffect, useRef, useState } from "react"
import {
  ICustomer,
  IReservation,
} from "../../features/reservation/reservationInterface"
import CustomerInfoAvatar from "./CustomerInfoAvatar"
import Button from "../button/Button"
import { useDispatch } from "react-redux"
import {
  setActiveReservation,
  setReservations,
} from "../../features/reservation/reservationSlice"
import { get, post } from "../../helpers/api"
import ArrowSmall from "../svg/ArrowSmall"
import Loader from "../svg/Loader"
import { showSnackbar } from "../../helpers/notification"
import { resetReservations } from "../../helpers/reservation"
import { useNavigate } from "react-router-dom"

export default function SelectReservationTile({
  index,
  reservation,
}: {
  index: number
  reservation: IReservation
}) {
  const [customers, setCustomers] = useState<JSX.Element[]>([])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const mount = useRef(true)
  const [resourcesLoaded, setResourcesLoaded] = useState<boolean>(false)
  const [resourcesAvailable, setResourcesAvailable] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    // Only run on mount
    if (mount.current) {
      mount.current = false
      if (
        reservation.AssignedResourceLocked === true &&
        reservation.AssignedResourceId !== null
      ) {
        setResourcesLoaded(true)
        setResourcesAvailable(true)
      } else {
        checkResourceAvailability()
      }
    }
  }, [])

  const checkResourceAvailability = () => {
    /**
     * Check available resource so we do not do the whole process
     */
    get({
      url: `resource/availability`,
      params: {
        reservationId: reservation.ReservationId,
        refreshReservation: "false",
      },
    })
      .then((resourceResponse) => {
        setResourcesLoaded(true)
        if (resourceResponse.data && resourceResponse.data.Success) {
          setResourcesAvailable(true)
        }
      })
      .catch((error) => {
        showSnackbar(error.response?.Message, "error", "top-center")
      })
  }

  const activateReservation = (
    reservationId: IReservation["ReservationId"],
  ): void => {
    setLoading(true)

    /**
     * Set reservation state to checking-ing
     * Set active reservation
     * Refresh reservations
     */
    get({
      url: "reservation/checking-in/" + reservationId,
      params: {},
    })
      .then((response) => {
        
        if (response.data && response.data.Success) {
          dispatch(setReservations(response.data.Reservations))
          showSnackbar(response.data.Message, "success", "top-center")
          dispatch(setActiveReservation(reservationId))
          navigate("/kiosk/check-in/customer-form/0")
        } else {
          showSnackbar(response.data.Message, "error", "top-center")
        }
        setLoading(false)
      })
      
      .catch((error) => {
        showSnackbar(error.response?.data.message, "error", "top-center")
        setLoading(false)
      })
  }

  const addRoomMate = (index: number) => {
    dispatch(setActiveReservation(reservation.ReservationId))

    setTimeout(() => {
      navigate(`/kiosk/reservation/customer-form/${index}`)
    }, 300)
  }

  const replaceWristband = (index: number) => {
    dispatch(setActiveReservation(reservation.ReservationId))
    navigate(`/kiosk/reservation/scan-wristband/${index}`)
  }

  useEffect(() => {
    let customersArray = []

    for (let index = 0; index < reservation.PersonCount; index++) {
      if (reservation.Customers[index]) {
        customersArray.push(
          <CustomerInfoAvatar
            customer={reservation.Customers[index]}
            key={index}
            cta={reservation.State === "started" ? <Button type="button" style="secondary" text="(Re) Scan wristband" size="small" onClick={() => replaceWristband(index)} /> : <></>}
          />,
        )
      } else {
        customersArray.push(
          <CustomerInfoAvatar
            customer={{
              CustomerId: "",
              Sex: "Male", 
              FirstName: "Empty",
              LastName: "Slot",
              Email: "",
            }}
            key={index}
            cta={reservation.State === "started" ? <Button type="button" style="secondary" text="Add traveller" size="small" onClick={() => addRoomMate(index)} /> : <></>}
          />,
        )
      }
    }
    setCustomers(customersArray)
  }, [reservation.Customers])

  return (
    <div
      className={`flex flex-col p-6 bg-white bg-opacity-75 rounded-xl ${
        reservation.State === "checking-in" ? "opacity-25" : ""
      }`}
    >
      <div className="text-h3 text-center">
        {index + 1}. {reservation.ResourceCategoryName}
      </div>
      <div className="text-sm text-center">{reservation.ReservationNumber}</div>
      <div
        className={`flex-grow grid grid-cols-2 gap-6 my-4 ${
          reservation.State === "started" ? "opacity-100" : "opacity-25"
        }`}
      >
        {customers}
      </div>
      {resourcesLoaded === true &&
        resourcesAvailable === true &&
        reservation.State === "confirmed" && (
          <Button
            type="button"
            onClick={() => activateReservation(reservation.ReservationId)}
            text={`Check in`}
            size="small"
            loading={loading}
            suffix={loading ? <Loader /> : <ArrowSmall />}
          />
        )}
      {resourcesLoaded === true &&
        resourcesAvailable === true &&
        reservation.State === "canceled" && (
          <Button type="button" text="Canceled" style="error" size="small" />
        )}
      {resourcesLoaded === true &&
        resourcesAvailable === true &&
        reservation.State === "checking-in" && (
          <Button type="button" text="Checking-in" style="info" size="small" />
        )}
      {resourcesLoaded === true &&
        resourcesAvailable === true &&
        reservation.State === "started" && (
          <div className="flex flex-col space-y-2 w-full">
            <Button
              type="button"
              onClick={() => activateReservation(reservation.ReservationId)}
              text={`Check-in (again)`}
              size="small"
              loading={loading}
            />
          </div>
        )}
      {resourcesLoaded === true && resourcesAvailable === false && (
        <Button
          type="button"
          text="No rooms available"
          style="error"
          size="small"
        />
      )}
      {resourcesLoaded === false && resourcesAvailable === false && (
        <Button type="button" text="Checking availability" style="info" size="small" suffix={<Loader/>} />
      )}
    </div>
  )
}
