import { IReservation } from "./reservationInterface";
import { post } from "../../helpers/api";

/**
 * Update reservation state
 * @param reservationId
 * @param state
 */
export function updateReservationState(
  reservationId: IReservation['ReservationId'],
  state: IReservation['State']
) {
  return post({
    url: "payment/pos/add",
    params: {
      reservationId,
      state,
    },
  });
}
