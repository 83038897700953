import { useNavigate } from "react-router-dom"
import { IOpenBills } from "../../features/reservation/reservationInterface"
import Button from "../button/Button"
import ArrowSmall from "../svg/ArrowSmall"
import { useDispatch, useSelector } from "react-redux"
import {
  clearLoadingOverlay,
  setLoadingOverlay,
} from "../../features/application/appSlice"
import { get, post } from "../../helpers/api"
import {
  selectActiveReservation,
  selectReservations,
  setOpenBills,
  setReservations,
} from "../../features/reservation/reservationSlice"
import { showSnackbar } from "../../helpers/notification"
import OpenBillsCard from "./OpenBillsCard"

interface IPayment {
  openBills: IOpenBills
  minimalAmount: number
  callBack: () => void
}

export default function Payment({ openBills, minimalAmount, callBack }: IPayment) {
  const dispatch = useDispatch()
  const reservations = useSelector(selectReservations)

  const handleSubmit = () => {
    // Send payment request to API
    if (openBills) {
      dispatch(
        setLoadingOverlay({
          show: true,
          message: "Creating payment...",
          seconds: 60,
        }),
      )
      post({
        url: "transaction/pos/add",
        params: {
          accountId: openBills.AccountId,
          amount: openBills.Total.Value,
        },
      })
        .then((response) => {
          if (response.data && response.data.Success) {
            dispatch(
              setLoadingOverlay({
                show: true,
                message: "Please pay using terminal.",
                seconds: 120,
              }),
            )
            showSnackbar(response.data.Message, "success", "top-center")
            setTimeout(() => {
              getStatus(response.data.Reference)
            }, 3000)
          } else {
            dispatch(clearLoadingOverlay())
            showSnackbar(response.data.Message, "error", "top-center")
          }
        })
        .catch((error) => {
          dispatch(clearLoadingOverlay())
          showSnackbar(error.response?.data.message, "error", "top-center")
        })
    }
  }

  /**
   * Get the transaction status
   * If transaction is in progress wait 3 seconds and call this function again
   * @param reference
   */
  const getStatus = (reference: string) => {
    get({
      url: "transaction/status",
      params: {
        reservationGroupId: reservations[0].ReservationGroupId,
        reference: reference,
        success: "success"
      },
    }).then((response) => {
      if (response.data && response.data.Status === "pending") {
        dispatch(
          setLoadingOverlay({
            show: true,
            message: response.data.Message,
            seconds: 60,
          }),
        )
        // Wait 3 seconds and call this function again
        setTimeout(() => {
          getStatus(reference)
        }, 3000)
      } else if (response.data && response.data.Status === "success") {
        dispatch(clearLoadingOverlay())
        dispatch(setReservations(response.data.Reservations))
        dispatch(setOpenBills(response.data.OpenBills))
        callBack()
      } else {
        showSnackbar(response.data.Message, "error", "top-center")
        dispatch(clearLoadingOverlay())
      }
    })
  }

  return (
    <>
      
      <OpenBillsCard openBills={openBills} />

      {openBills.Total.Value > minimalAmount && (
        <div className="mt-6">
          <Button
            type="button"
            onClick={() => {
              handleSubmit()
            }}
            text="Pay"
            suffix={<ArrowSmall />}
          />
        </div>
      )}
    </>
  )
}
