import { Link } from "react-router-dom"

type IButtonTileProps = {
  icon?: JSX.Element
  text: string
  onClick?: () => void
}

export default function ButtonTile(props: IButtonTileProps) {
  const { icon, text, onClick = undefined } = props

  return (
    <button
      className="bg-white text-black p-10 rounded-md flex justify-center items-center flex-col"
      onClick={onClick}
    >
      {icon && <div>{icon}</div>}
      <span className="mt-2 text-3xl text-center">{text}</span>
    </button>
  )
}
