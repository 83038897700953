import { useState } from "react"
import Button from "../../../components/button/Button"
import { Checkbox } from "../../../components/form/Checkbox"
import ArrowSmall from "../../../components/svg/ArrowSmall"
import { useNavigate } from "react-router-dom"
import Loader from "../../../components/svg/Loader"
import { useDispatch, useSelector } from "react-redux"
import { showSnackbar } from "../../../helpers/notification"
import {
  selectActiveReservation,
  selectOpenBills,
  selectReservations,
  setOpenBills,
  setReservations,
} from "../../../features/reservation/reservationSlice"
import { selectConfig } from "../../../features/config/configSlice"
import {
  clearLoadingOverlay,
  setLoadingOverlay,
} from "../../../features/application/appSlice"
import { get, post } from "../../../helpers/api"
import Eca from "../../../components/svg/paymentMethods/Eca"
import Vis from "../../../components/svg/paymentMethods/Vis"
import Adyen from "../../../components/svg/paymentMethods/Adyen"

export default function Consent() {
  const dispatch = useDispatch()
  const [consentValue, setConsentValue] = useState(false)
  const [authorizable, setAuthorizable] = useState(true)
  const [showArrow, setShowArrow] = useState(false)
  const navigate = useNavigate()
  const config = useSelector(selectConfig)
  const activeReservation = useSelector(selectActiveReservation)
  const reservations = useSelector(selectReservations)

  const handleSubmit = () => {
    if (!consentValue) {
      showSnackbar("Please check the checkbox", "error", "top-center")
      return false
    } else if (activeReservation && config.Property) {
      dispatch(
        setLoadingOverlay({
          show: true,
          message: "Creating pre authorization...",
          seconds: 60,
        }),
      )
      /**
       * Create a new pre auth transaction
       */
      post({
        url: "transaction/pre-auth/add",
        params: {
          reservationId: activeReservation.ReservationId,
          accountId: activeReservation.AccountId,
          amount: config.Property?.PreAuthConsentAmount,
        },
      })
        .then((response) => {
          if (response.data && response.data.Success) {
            dispatch(
              setLoadingOverlay({
                show: true,
                message: "Please authorize using payment terminal.",
                seconds: 120,
              }),
            )
            setShowArrow(true)
            showSnackbar(response.data.Message, "success", "top-center")
            setTimeout(() => {
              getStatus(response.data.Reference)
            }, 3000)
          } else {
            dispatch(clearLoadingOverlay())
            showSnackbar(response.data.Message, "error", "top-center")
          }
        })
        .catch((error) => {
          dispatch(clearLoadingOverlay())
          showSnackbar(error.response?.data.message, "error", "top-center")
        })
    }
  }

  /**
   * Get the transaction status
   * If transaction is in progress wait 3 seconds and call this function again
   * @param reference
   */
  const getStatus = (reference: string) => {
    get({
      url: "transaction/status",
      params: {
        reservationGroupId: reservations[0].ReservationGroupId,
        reference: reference,
        success: "authorised",
      },
    }).then((response) => {
      /**
       * Authorisation is still pending
       * Check again in 3 seconds
       */
      if (response.data && response.data.Status === "pending") {
        dispatch(
          setLoadingOverlay({
            show: true,
            message: response.data.Message,
            seconds: 60,
          }),
        )

        /**
         * Wait 3 seconds and call this function again
         */
        setTimeout(() => {
          getStatus(reference)
        }, 3000)
      } else if (response.data && response.data.Status === "authorised") {
        /**
         * Pre authorisation is authorised.
         * Customer can use the bar, the api will update the amount to the full amount.
         */

        setShowArrow(false)
        dispatch(clearLoadingOverlay())
        dispatch(setReservations(response.data.Reservations))
        dispatch(setOpenBills(response.data.OpenBills))

        selectResource()
      } else if (response.data && response.data.Status === "reject") {
        /**
         * Authorisation is rejected (reject)
         * Customer can not use the bar and has to pay openbills.
         * Show the message and redirect them to openbills, if they have any, or to resource
         * Api will cashlist the customer on check in
         */
        dispatch(
          setLoadingOverlay({
            show: true,
            message: response.data.Message,
            seconds: 3,
          }),
        )
        setShowArrow(false)
        setAuthorizable(false)
      } else {
        /**
         * Something went wrong, try again
         */
        showSnackbar(response.data.Message, "error", "top-center")
        dispatch(clearLoadingOverlay())
      }
    })
  }

  /**
   * Navigate to the next step
   */
  const selectResource = () => {
    if (activeReservation?.ResourceCategoryName === "Room") {
      navigate("/kiosk/check-in/select-resource")
    } else {
      navigate("/kiosk/check-in/select-resource-video")
    }
  }

  /**
   * When a authorisation fails we need to check the open bills.
   * Navigate to the next step if there are no open bills.
   */
  const checkOpenbills = () => {
    if (
      activeReservation !== undefined &&
      activeReservation.OpenBills.Total.Value > 0.1
    ) {
      navigate("/kiosk/check-in/open-bills-customer")
    } else {
      selectResource()
    }
  }

  return (
    <>
      <div className="flex-grow flex justify-center items-center flex-col w-full">
        {authorizable && (
          <>
            <div className="text-h2 text-center">
              I authorize CityHub to charge my account
              <br />
              in case of broken or missing items.
            </div>

            <div className="mt-12">
              <Checkbox
                label={`Yes, I authorize CityHub`}
                defaultValue={consentValue}
                checked={consentValue}
                onChange={(e) => {
                  setConsentValue(e.target.checked)
                }}
              />
            </div>
          </>
        )}

        {config.Property?.PreAuthEnabled === false && (
          <>
            <div className="mt-12 w-full">
              <Button
                type="button"
                onClick={() => {
                  checkOpenbills()
                }}
                text="Next"
                style={consentValue ? "primary" : "primaryDisabled"}
                suffix={<ArrowSmall />}
              />
            </div>
          </>
        )}

        {config.Property?.PreAuthEnabled === true && authorizable && (
          <>
            <div className="mt-12 w-full">
              <Button
                type="button"
                onClick={() => {
                  handleSubmit()
                }}
                text="Authorize"
                style={consentValue ? "primary" : "primaryDisabled"}
                suffix={<ArrowSmall />}
              />
            </div>
            <div className="flex justify-center items-center space-x-2 mt-6">
              <Eca />
              <Vis />
              <Adyen />
            </div>
          </>
        )}

        {!authorizable && (
          <>
            <div className="text-h2 text-center">
              Unfortunately we can't authorize your account.
              <br />
              {/* {config.Property?.PreAuthCashlist === true && (
                <span>We will cashlist your account.</span>
              )} */}
            </div>
            <div className="mt-12 w-full">
              <Button
                type="button"
                onClick={() => {
                  checkOpenbills()
                }}
                text="Proceed without authorization"
                style="primary"
                suffix={<ArrowSmall />}
              />
            </div>
          </>
        )}

        {config.Property?.PreAuthEnabled === true && (
          <div className="fixed bottom-4 left-4 w-48 opacity-10">
            <Button
              type="button"
              onClick={() => {
                setAuthorizable(!authorizable)
              }}
              text={authorizable ? "Cancel" : "Authorize"}
              style={consentValue ? "secondary" : "secondaryDisabled"}
              suffix={<ArrowSmall />}
            />
          </div>
        )}

        {showArrow === true && (
          <div className="fixed bottom-8 right-8 z-50 animate-bounce-45">
            <ArrowSmall className="w-48 h-48 rotate-45" />
          </div>
        )}
      </div>
    </>
  )
}
