import Hub from "../svg/Hub"
import { ucFirst } from "../../helpers/base"
import { IResource } from "../../features/reservation/reservationInterface"
import Logo from "../svg/Logo"
import Room from "../svg/Room"

export default function AccomodationTile({
  resource,
  type,
  onClick = undefined,
}: {
  resource: IResource | undefined
  type: string
  onClick?: () => void
}) {

  return (
    <div
      className={
        resource?.State === "unavailable"
          ? "opacity-50 pointer-events-none"
          : "cursor-pointer"
      }
      onClick={onClick}
    >
      <div
        className={`bg-white bg-opacity-75 text-black flex flex-col items-center rounded-t-xl p-6 `}
      >
        {type !== "room" && (
          <Hub className={`w-24 h-24 rotate-${type === "top" ? 0 : 180} `} />
        )}
        {type === "room" && <Room />}
        <span className="mt-2">{resource?.Name}</span>
      </div>
      <div className="bg-black text-white text-center rounded-b-xl p-4">
        {ucFirst(type)}
      </div>
    </div>
  )
}
