import Button from "../../../components/button/Button"
import ArrowSmall from "../../../components/svg/ArrowSmall"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
  selectActiveReservation,
  selectOpenBills,
  setOpenBills,
  setReservations,
} from "../../../features/reservation/reservationSlice"
import Payment from "../../../components/kiosk/Payment"
import OpenBillsCard from "../../../components/kiosk/OpenBillsCard"
import {
  clearLoadingOverlay,
  setLoadingOverlay,
} from "../../../features/application/appSlice"
import { get, post } from "../../../helpers/api"
import { showSnackbar } from "../../../helpers/notification"

export default function PreAuth() {
  const dispatch = useDispatch()
  const openBills = useSelector(selectOpenBills)
  const navigate = useNavigate()
  const activeReservation = useSelector(selectActiveReservation)

  /**
   * Send request to api to capture the amount at Adyen
   * Because we are authorised we do not wait for the capture feedback
   */
  const handleSubmit = () => {
    if (activeReservation) {
      dispatch(
        setLoadingOverlay({
          show: true,
          message: "Processing...",
          seconds: 60,
        }),
      )
      post({
        url: "transaction/pre-auth/capture",
        params: {
          reference: activeReservation.Transaction.Reference,
          amount: activeReservation.OpenBills.Total.Value,
        },
      })
        .then((response) => {
          if (response.data && response.data.Success) {
            showSnackbar(response.data.Message, "success", "top-center")
            navigate("/kiosk/check-out/feedback")
            dispatch(clearLoadingOverlay())

            // setTimeout(() => {
            //   getStatus()
            // }, 3000)
          } else {
            showSnackbar(response.data.Message, "error", "top-center")
          }
        })
        .catch((error) => {
          dispatch(clearLoadingOverlay())
          showSnackbar(error.response?.data.message, "error", "top-center")
        })
    }
  }

  /**
   * Get the transaction status
   * If transaction is in progress wait 3 seconds and call this function again
   * @param reference
   */
  // const getStatus = () => {
  //   if (activeReservation) {
  //     get({
  //       url: "transaction/status",
  //       params: {
  //         reservationGroupId: activeReservation.ReservationGroupId,
  //         reference: activeReservation.Transaction.Reference,
  //         success: "captured",
  //       },
  //     }).then((response) => {
  //       if (response.data && response.data.Status === "capture_pending") {
  //         dispatch(
  //           setLoadingOverlay({
  //             show: true,
  //             message: response.data.Message,
  //             seconds: 60,
  //           }),
  //         )
  //         // Wait 3 seconds and call this function again
  //         setTimeout(() => {
  //           getStatus()
  //         }, 3000)
  //       } else if (response.data && response.data.Status === "captured") {
  //         dispatch(clearLoadingOverlay())
  //         dispatch(setReservations(response.data.Reservations))
  //         dispatch(setOpenBills(response.data.OpenBills))
  //         navigate("/kiosk/check-out/feedback")
  //       } else {
  //         showSnackbar(response.data.Message, "error", "top-center")
  //         dispatch(clearLoadingOverlay())
  //       }
  //     })
  //   }
  // }

  return (
    <>
      {activeReservation !== undefined && (
        <>
          <div className="flex-grow flex justify-center items-center flex-col w-full">
            <div className="text-h1 text-center">
              These have been your consumptions during your stay.
            </div>
            <div className="mt-12 w-full">
              <OpenBillsCard openBills={activeReservation.OpenBills} />
            </div>
            <div className="mt-6 flex justify-between items-center w-full text-h3 text-center">
              <Button
                onClick={() => handleSubmit()}
                type="button"
                text="Charge my account"
                suffix={<ArrowSmall />}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
