import { SVGProps } from "react"

export default function Eca(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="34"
      height="24"
      viewBox="0 0 34 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="33"
        height="23"
        rx="3.5"
        fill="white"
        stroke="#E6E6E6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.179 16.7995C15.9949 17.7975 14.459 18.4 12.7807 18.4C9.03582 18.4 6 15.4003 6 11.7C6 7.99969 9.03582 5 12.7807 5C14.459 5 15.9949 5.6025 17.179 6.60054C18.363 5.6025 19.8989 5 21.5773 5C25.3221 5 28.358 7.99969 28.358 11.7C28.358 15.4003 25.3221 18.4 21.5773 18.4C19.8989 18.4 18.363 17.7975 17.179 16.7995Z"
        fill="#ED0006"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.179 16.7995C18.6369 15.5706 19.5614 13.742 19.5614 11.7C19.5614 9.65804 18.6369 7.82944 17.179 6.60054C18.363 5.6025 19.8989 5 21.5773 5C25.3221 5 28.3579 7.99969 28.3579 11.7C28.3579 15.4003 25.3221 18.4 21.5773 18.4C19.8989 18.4 18.363 17.7975 17.179 16.7995Z"
        fill="#F9A000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.179 16.7994C18.6369 15.5705 19.5614 13.7419 19.5614 11.7C19.5614 9.65807 18.6369 7.82947 17.179 6.60057C15.7211 7.82947 14.7966 9.65807 14.7966 11.7C14.7966 13.7419 15.7211 15.5705 17.179 16.7994Z"
        fill="#FF5E00"
      />
    </svg>
  )
}
