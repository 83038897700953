import { useDispatch, useSelector } from "react-redux"
import ButtonTile from "../../components/button/ButtonTile"
import ArrowLeftOnRectangle from "../../components/svg/ArrowLeftOnRectangle"
import ArrowRightOnRectangle from "../../components/svg/ArrowRightOnRectangle"
import CreditCard from "../../components/svg/CreditCard"
import HomeModern from "../../components/svg/HomeModern"
import { selectConfig, setApiUuid } from "../../features/config/configSlice"
import Button from "../../components/button/Button"
import { useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { v4 as uuid } from "uuid";

export default function Home() {
  const config = useSelector(selectConfig)
  const dispatch = useDispatch()
  const [searchString, setSearchString] = useState<string>("")
  const inputRef: any = useRef(null)
  const navigate = useNavigate()

  const goToCheckIn = () => {
    // Create unique id for api thread
    dispatch(setApiUuid(uuid()))
    navigate("/kiosk/check-in")
  }

  const goToCheckOut = () => {
    // Create unique id for api thread
    
    dispatch(setApiUuid(uuid()))
    navigate("/kiosk/check-out")
  }

  const goToExpense = () => {
    // Create unique id for api thread
    dispatch(setApiUuid(uuid()))
    navigate("/kiosk/check-expense")
  }

  return (
    <div className="flex-grow flex flex-col justify-center items-center">
      <div className="absolute top-4 right-4 flex space-x-6 whitespace-nowrap">
        {config.Property?.BookingModuleUrl && (
          <Button
            link={config.Property?.BookingModuleUrl}
            prefix={<HomeModern />}
            text="Book now"
            style="info"
            size="small"
          />
        )}
        <Button
          onClick={() => goToExpense()}
          type="button"
          prefix={<CreditCard />}
          text="Check Expense"
          style="info"
          size="small"
        />
      </div>
      <div className="text-h1 text-center">
        Welcome to
        <br />
        {config.Property?.Name}
      </div>

      <div className="mt-12 grid grid-cols-2 gap-12">
        <ButtonTile
          onClick={() => goToCheckIn()}
          icon={<ArrowLeftOnRectangle className="w-10 h-10 rotate-180" />}
          text="Check in"
        />
        <ButtonTile
          onClick={() => goToCheckOut()}
          icon={<ArrowRightOnRectangle className="w-10 h-10" />}
          text="Check out"
        />
      </div>
    </div>
  )
}
