import { ICustomer } from "../../features/reservation/reservationInterface"
import User from "../svg/User"
import UserPlus from "../svg/UserPlus"

export default function CustomerInfoAvatar({
  customer,
  cta,
}: {
  customer: ICustomer
  cta?: JSX.Element
}) {
  return (
    <div className="flex flex-col items-center">
      <div className="bg-gray-400 rounded-full w-16 h-16 relative ring-4 ring-white flex items-center justify-center">
        {customer.CustomerId === "" && <UserPlus />}
        {customer.CustomerId !== "" && <User />}
      </div>
      <div className="mt-2 text-center">{customer.FirstName}</div>
      {cta && <div className="mt-2 grid justify-items-stretch w-full">{cta}</div>}
    </div>
  )
}
