import { useDispatch, useSelector } from "react-redux"
import { InputField } from "../../../components/form/InputField"
import { FormEvent, useEffect, useState } from "react"
import Button from "../../../components/button/Button"
import ArrowSmall from "../../../components/svg/ArrowSmall"
import {
  selectActiveReservation,
  setReservations,
} from "../../../features/reservation/reservationSlice"
import { post } from "../../../helpers/api"
import { useNavigate, useParams } from "react-router-dom"
import Loader from "../../../components/svg/Loader"
import { showSnackbar } from "../../../helpers/notification"
import {
  clearLoadingOverlay,
  setLoadingOverlay,
} from "../../../features/application/appSlice"
import { RadioList } from "../../../components/form/RadioList"

export default function CustomerForm() {
  let { index } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const activeReservation = useSelector(selectActiveReservation)
  const [loading, setLoading] = useState<boolean>(false)
  const [customerIndex, setCustomerIndex] = useState<number>(0)
  const [customer, setCustomer] = useState({
    customerId: "",
    firstName: "",
    lastName: "",
    email: "",
    sex: "",
  })
  const [errorMessage, setErrorMessage] = useState({
    firstName: "",
    lastName: "",
    email: "",
    sex: "",
  })

  // Set customer index. Should be a number
  useEffect(() => {
    if (index !== undefined) {
      setCustomerIndex(parseInt(index))
    }
  }, [index])

  // Set the customer data when customerIndex changes
  useEffect(() => {
    if (activeReservation?.Customers[customerIndex] !== undefined) {
      setCustomer((customer) => ({
        ...customer,
        ...{
          customerId: activeReservation.Customers[customerIndex].CustomerId,
          firstName: activeReservation.Customers[customerIndex].FirstName,
          lastName: activeReservation.Customers[customerIndex].LastName,
          email: activeReservation.Customers[customerIndex].Email,
          sex: activeReservation.Customers[customerIndex].Sex,
        },
      }))
    } else {
      resetCustomer()
    }
  }, [customerIndex])

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    updateCustomer()
  }

  const updateCustomer = () => {
    // Call API to save customer
    if (activeReservation) {
      setLoading(true)
      dispatch(
        setLoadingOverlay({
          show: true,
          message: "Saving your details",
          seconds: 5,
        }),
      )
      post({
        url: "customer/update",
        params: {
          ...customer,
          ...{ reservationId: activeReservation?.ReservationId as string },
        },
      })
        .then((response) => {
          if (response.data && response.data.Success) {
            showSnackbar(response.data.Message, "success", "top-center")
            dispatch(setReservations(response.data.Reservations))

            goToNextPage(false)

            setLoading(false)
            dispatch(clearLoadingOverlay())
          } else {
            showSnackbar(response.data.Message, "error", "top-center")
            setLoading(false)
            dispatch(clearLoadingOverlay())
          }
        })
        .catch((error) => {
          dispatch(clearLoadingOverlay())
          showSnackbar(error.response?.data.message, "error", "top-center")
          setErrorMessage(error.response?.data.errors)
          setLoading(false)
        })
    }
  }

  const goToNextPage = (skip: boolean) => {
    if (activeReservation) {
      // Go to next customer
      navigate(`/kiosk/reservation/scan-wristband/${index}`)
    }
  }

  const setCustomerValues = (name: string, value: string | number) => {
    setCustomer({ ...customer, [name]: value })
    setErrorMessage({ ...errorMessage, [name]: "" })
  }

  const resetCustomer = () => {
    setCustomer((customer) => ({
      ...customer,
      ...{
        customerId: "",
        firstName: "",
        lastName: "",
        email: "",
        sex: "",
      },
    }))
    setErrorMessage((error) => ({
      ...error,
      ...{
        firstName: "",
        lastName: "",
        email: "",
        sex: "",
      },
    }))
  }

  return (
    <form
      className="flex-grow flex justify-center items-center flex-col w-full"
      onSubmit={(e) => {
        handleFormSubmit(e)
      }}
    >
      <div className="text-h1 text-center">
        Traveller {customerIndex + 1},
        <br /> Please update your details.
      </div>
      <div className="mt-6 w-3/4 space-y-4">
        <RadioList
          name="sex"
          label="Sex"
          disabled={loading}
          defaultValue={customer.sex}
          value={customer.sex === null ? "NULL" : customer.sex}
          options={{ "Mr.": "Male", "Ms.": "Female", "Mx.": "NULL" }}
          errorMessage={errorMessage.sex}
          onChange={(e) => {
            setCustomerValues("sex", e.target.value)
          }}
        />

        <InputField
          type="text"
          name="firstName"
          label="First name"
          placeholder="First name"
          disabled={loading}
          defaultValue={customer.firstName}
          value={customer.firstName}
          errorMessage={errorMessage.firstName}
          onChange={(e) => {
            setCustomerValues("firstName", e.target.value)
          }}
        />

        <InputField
          type="text"
          name="lastName"
          label="Last name"
          placeholder="Last name"
          disabled={loading}
          defaultValue={customer.lastName}
          value={customer.lastName}
          errorMessage={errorMessage.lastName}
          onChange={(e) => {
            setCustomerValues("lastName", e.target.value)
          }}
        />

        <InputField
          type="text"
          name="email"
          label="Email address"
          placeholder="Email address"
          disabled={loading}
          defaultValue={customer.email}
          value={customer.email}
          errorMessage={errorMessage.email}
          onChange={(e) => {
            setCustomerValues("email", e.target.value)
          }}
        />
      </div>
      <div className="mt-6 w-3/4">
        <Button
          text={`Save and scan wristband`}
          suffix={loading ? <Loader /> : <ArrowSmall />}
          type="submit"
        />
      </div>
    </form>
  )
}
