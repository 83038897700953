import { useSelector } from "react-redux";
import { selectMapScreen } from "../../features/mapScreen/mapScreenSlice";
import { selectChatRoom } from "../../features/chatRoom/chatRoomSlice";
import { updateHotelPlacesReservationId } from "../../helpers/firebase";
import XMark from "../svg/XMark";
import { IPlace } from "../../features/mapScreen/mapScreenInterface";
import { showSnackbar } from "../../helpers/notification";
import DevicePhoneMobile from "../svg/DevicePhoneMobile";
import { sendPlace } from "../../helpers/mapScreen";

export function AddToMyPlaces({ place }: { place: IPlace }) {
	const mapScreen = useSelector(selectMapScreen);
	const chatRoom = useSelector(selectChatRoom);

	const disconnect = () => {
		updateHotelPlacesReservationId(mapScreen.HotelDocId, "");
	};

	return (
		<div
			className="inline-block p-4 bg-black rounded-md text-white cursor-pointer"
			onClick={() => {
				showSnackbar(`${place.Name} is sent.`, "success", "top-center");
				sendPlace(place);
			}}
		>
			<DevicePhoneMobile />
		</div>
	);
}
