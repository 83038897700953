import { FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputField } from "../form/InputField";
import SubmitSuffix from "../button/SubmitSuffix";
import { get } from "../../helpers/api";
import { showSnackbar } from "../../helpers/notification";
import {
	MsClearSearchPlaces,
	MsSetSearchPlaces,
	MsSetSelectedPlace,
	selectMapScreen,
} from "../../features/mapScreen/mapScreenSlice";
import { AnimatePresence, motion } from "framer-motion";
import Loading from "../svg/Loading";
import { SearchPlace } from "./SearchPlace";
import { IPlaceAutoComplete } from "../../features/mapScreen/mapScreenInterface";
import ArrowSmall from "../svg/ArrowSmall";

export function SearchPlaces() {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState<boolean>(false);
	const [showAutocomplete, setShowAutocomplete] = useState<boolean>(false);
	const [suggestions, setSuggestions] = useState<IPlaceAutoComplete[]>([]);
	const [q, setQ] = useState<string>("");
	const mapScreen = useSelector(selectMapScreen);

	useEffect(() => {
		if (mapScreen.State === "CityHost") {
			setQ("");
		}
	}, [mapScreen.State]);

	/**
	 * Submit form, used when hitting enter
	 * @param e
	 */
	const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		searchPlace(q);
	};

	/**
	 * Search location based on query via ChApi
	 * @param query
	 */
	const searchPlace = (query: string) => {
    setSuggestions([]);
    setShowAutocomplete(false);

		if (query.length) {
			dispatch(MsClearSearchPlaces());
			setLoading(true);
			get({ url: `map-screen/search-places`, params: { q: query } })
				.then((response) => {
					if (response.data && response.data.Success) {
            if(response.data.Places.length === 1){
              dispatch(MsSetSelectedPlace(response.data.Places[0]))
            } else {
              dispatch(MsSetSearchPlaces(response.data.Places));
            }
						setLoading(false);
					} else {
						showSnackbar(response.data.Message, "error", "bottom-center");
					}
				})
				.catch((error) => {
					showSnackbar(error.response?.Message, "error", "bottom-center");
				});
		} else {
			showSnackbar(
				"Please enter what you are looking for.",
				"error",
				"bottom-center",
			);
		}
	};

  const resetSearch = () => {
    dispatch(MsClearSearchPlaces());
    dispatch(MsSetSelectedPlace(null));
  }

  const autoComplete = (input: string) => {
    setQ(input);

    if(input.length > 2){
      setShowAutocomplete(true);
      get({ url: `map-screen/search-places-autocomplete`, params: { q: input } })
				.then((response) => {
					if (response.data && response.data.Success) {
            setSuggestions(response.data.Suggestions);
					} else {
						showSnackbar(response.data.Message, "error", "bottom-center");
					}
				})
    } else {
    setSuggestions([]);
    setShowAutocomplete(false);
    }
  }

  const handleAutocompleteClick = (place: IPlaceAutoComplete) => {
    setQ(place.Name);
    searchPlace(place.Name);
  }

	return (
		<>
			<AnimatePresence>
				{showAutocomplete && suggestions && (
					<motion.div
						initial={{ y: 20, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						exit={{ y: 20, opacity: 0 }}
						className="absolute bottom-24 left-4 right-4 z-20 flex flex-col items-center"
					>
						<div className="w-[400px] text-h4 rounded-md text-black bg-white shadow-md divide-y divide-dashed">
              {suggestions.map((suggestion, i) => 
                <div className="px-4 py-2 flex justify-between items-center" key={i} onClick={() => {handleAutocompleteClick(suggestion);}}>
                  <span>{suggestion.Name}</span>
                  <ArrowSmall />
                </div>
              )}
            </div>
					</motion.div>
				)}
			</AnimatePresence>
	
			<AnimatePresence>
				{mapScreen.State === "Map" && (
					<motion.div
						initial={{ y: 20, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						exit={{ y: 20, opacity: 0 }}
						className="absolute bottom-12 left-4 right-4 z-20 rounded-md flex flex-col items-center text-white"
					>
						<form
							onSubmit={(e) => {
								handleFormSubmit(e);
							}}
							className="w-[400px] text-h4"
						>
							<InputField
								type="text"
								name="q"
								placeholder="What are you looking for?"
								defaultValue={q}
								value={q}
								onChange={(e) => {
									autoComplete(e.target.value);
								}}
                onFocus={() => {
                  resetSearch();
                }}
								suffix={
									<SubmitSuffix
										onSubmit={() => searchPlace(q)}
										loading={loading}
									/>
								}
							/>
						</form>
					</motion.div>
				)}
			</AnimatePresence>

			<AnimatePresence>
				{(loading && !showAutocomplete) && (
					<motion.div
						initial={{ x: -300, opacity: 0 }}
						animate={{ x: 0, opacity: 1 }}
						exit={{ x: -300, opacity: 0 }}
						className="absolute bottom-32 left-4 right-4 w-full"
					>
						<div className="overflow-x-scroll w-full flex space-x-6 py-6 pr-4">
							{[1].map((i, index) => (
								<div
									className="flex-shrink-0 relative bg-cityhub-900 border-2 border-cithyhub-500 rounded-md flex flex-col basis-[10%] justify-between items-center cursor-pointer drop-shadow-xl opacity-75"
									key={index}
								>
									<div className="w-full object-cover rounded-md aspect-[3/4] flex justify-center items-center">
										<Loading className="w-12 h-12 animate-loading text-white" />
									</div>
								</div>
							))}
						</div>
					</motion.div>
				)}
			</AnimatePresence>

			<AnimatePresence>
				{(!loading && !showAutocomplete && mapScreen !== undefined) && (
					<motion.div
						initial={{ x: 300, opacity: 0 }}
						animate={{ x: 0, opacity: 1 }}
						exit={{ x: 300, opacity: 0 }}
						className="absolute bottom-24 left-4 z-20 right-4 w-full"
					>
						<div className="overflow-x-scroll w-full flex space-x-6 py-6 pr-4">
							{mapScreen.SearchPlaces?.map((place, index) => (
								<SearchPlace place={place} key={index} />
							))}
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</>
	);
}
