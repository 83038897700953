import {
  InputHTMLAttributes,
  createRef,
  forwardRef,
  useImperativeHandle,
} from "react"

interface IInputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  classes?: string
  defaultValue?: any
  errorMessage?: string
  options: { [key: string]: string }
}

export const RadioList = forwardRef((props: IInputFieldProps, ref) => {
  const inputRef = createRef<HTMLInputElement>()
  const {
    classes = "",
    name,
    disabled,
    label = undefined,
    defaultValue,
    value,
    onChange,
    errorMessage = "",
    options,
  } = props

  return (
    <>
      <div className="relative">
        <div className={`w-full  py-2 px-4 flex text-gray-900 ${classes}`}>
          <div className="flex-1">
            {label !== undefined && (
              <label htmlFor={name} className="text-gray-600 text-sm">
                {label}
              </label>
            )}
            <div className="flex space-x-4">
              {Object.keys(options).map((option, index) => {
                return (
                  <div className="flex" key={index}>
                    <input
                    id={`${name}-${option}`}
                      ref={inputRef}
                      className="text-gray-900 rounded-lg outline-none block w-full"
                      type="radio"
                      name={name}
                      checked={options[option] === value}
                      disabled={disabled}
                      defaultValue={options[option]}
                      onChange={onChange}
                    />
                    <label htmlFor={`${name}-${option}`} className="ml-2">{option}</label>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      {errorMessage && (
        <div className="mt-2 px-4 text-red-600 text-sm text-right">
          {errorMessage}
        </div>
      )}
    </>
  )
})
