import { SVGProps } from "react"

export default function Vis(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="34"
      height="24"
      viewBox="0 0 34 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="33"
        height="23"
        rx="3.5"
        fill="white"
        stroke="#E6E6E6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7503 15.8582H8.69051L7.14596 9.79237C7.07265 9.51334 6.91699 9.26666 6.68802 9.1504C6.11659 8.85823 5.48692 8.6257 4.8 8.50843V8.27489H8.11808C8.57603 8.27489 8.91948 8.6257 8.97673 9.03313L9.77813 13.4086L11.8369 8.27489H13.8394L10.7503 15.8582ZM14.9842 15.8582H13.039L14.6408 8.27489H16.586L14.9842 15.8582ZM19.1027 10.3757C19.1599 9.96728 19.5034 9.73374 19.9041 9.73374C20.5338 9.6751 21.2197 9.79238 21.7921 10.0835L22.1356 8.45081C21.5631 8.21727 20.9335 8.1 20.362 8.1C18.474 8.1 17.1002 9.15041 17.1002 10.6082C17.1002 11.7173 18.0733 12.2996 18.7603 12.6504C19.5034 13.0002 19.7896 13.2337 19.7324 13.5835C19.7324 14.1082 19.1599 14.3418 18.5885 14.3418C17.9016 14.3418 17.2147 14.1669 16.586 13.8747L16.2426 15.5085C16.9295 15.7996 17.6726 15.9169 18.3596 15.9169C20.4765 15.9745 21.7921 14.9251 21.7921 13.35C21.7921 11.3665 19.1027 11.2502 19.1027 10.3757ZM28.6 15.8582L27.0554 8.27489H25.3964C25.0529 8.27489 24.7095 8.50843 24.595 8.85823L21.7349 15.8582H23.7374L24.1371 14.7502H26.5975L26.8265 15.8582H28.6ZM25.6826 10.3171L26.254 13.1751H24.6523L25.6826 10.3171Z"
        fill="#172B85"
      />
    </svg>
  )
}
