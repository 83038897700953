import {
  InputHTMLAttributes,
  createRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from "react"

interface IInputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  defaultValue?: any
  checked: boolean
  errorMessage?: string
}

export const Checkbox = (props: IInputFieldProps) => {
  const inputRef = createRef<HTMLInputElement>()
  const {
    name,
    label,
    defaultValue,
    checked,
    errorMessage = "",
    onChange,
  } = props

  useEffect(() => {
    if (inputRef.current !== null) {
      inputRef.current.checked = checked
    }
  }, [checked])

  return (
    <div className="relative">
      <div className={`w-full rounded-lg py-2 px-4 flex text-gray-900`}>
        <div className="flex items-center mb-4">
          <input
            ref={inputRef}
            id={name}
            name={name}
            type="checkbox"
            // defaultValue={defaultValue}
            checked={checked}
            value="1"
            onChange={onChange}
            className="w-8 h-8 accent-green-600 rounded-xl"
          />
          <label htmlFor="default-checkbox" className="ml-2">
            {label}
          </label>
        </div>
      </div>
      {errorMessage && (
        <div className="mt-2 px-4 text-red-600 text-sm text-right">
          {errorMessage}
        </div>
      )}
    </div>
  )
}
