import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "../../helpers/api";
import { showSnackbar } from "../../helpers/notification";
import {
	selectMapScreen,
} from "../../features/mapScreen/mapScreenSlice";
import { panTo, selectPlace } from "../../helpers/mapScreen";
import { IPlace } from "../../features/mapScreen/mapScreenInterface";
import Logo from "../svg/Logo";

export function SearchPlace({ place }: { place: IPlace }) {
  const dispatch = useDispatch();
	const [photoUrl, setPhotoUrl] = useState<string | null>(null);
	const mapScreen = useSelector(selectMapScreen);

  useEffect(() => {
		if (place.Photo === null) {
			get({ url: `map-screen/get-place-photo`, params: { photo: place.PhotoUrl } })
				.then((response) => {
					if (response.data && response.data.Success) {
            setPhotoUrl(response.data.PhotoUrl);
            // dispatch(MsSetPhotoUrl(place));
					} else {
						showSnackbar(response.data.Message, "error", "bottom-center");
					}
				});
		}
	}, [place.PhotoUrl]);

	return (
		<div
			className={`flex-shrink-0 relative rounded-md flex flex-col basis-[10%] justify-between items-center cursor-pointer drop-shadow-xl border-2 ${
				mapScreen.SelectedPlace &&
				mapScreen.SelectedPlace.GoogleId === place.GoogleId
					? "bg-gray-800 border-gray-800 scale-105 transition-transform"
					: "bg-cityhub-500 border-cithyhub-500"
			}`}
			onClick={() => {
				panTo(place);
				selectPlace(place);
			}}
		>

			{place.Photo !== null && (
				<img
					src={place.Photo}
					className="w-full object-cover rounded-md aspect-[3/4]"
				/>
			)}

      {place.Photo === null && (
				<div className="w-full object-cover rounded-md aspect-[3/4] flex justify-center">
					<Logo className="w-12 h-12 text-white mt-8" />
				</div>
			)}
			
			<div
				className={`absolute bottom-0 w-full p-2 flex flex-col rounded-b-md ${
					mapScreen.SelectedPlace &&
					mapScreen.SelectedPlace.GoogleId === place.GoogleId
						? "bg-gray-800 text-white"
						: "bg-cityhub-500 text-black"
				}`}
			>
				<div className="flex justify-between items-center">
					<span className="text-sm">{place.Name.length > 30 ? place.Name.substring(0, 27) + "..." : place.Name}</span>
				</div>
			</div>
		</div>
	);
}
