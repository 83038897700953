import { useSelector } from "react-redux"
import {
  selectOpenBills,
  selectReservation,
} from "../../../features/reservation/reservationSlice"
import { useNavigate, useParams } from "react-router-dom"
import Button from "../../../components/button/Button"
import ArrowSmall from "../../../components/svg/ArrowSmall"
import { useEffect, useState } from "react"
import { IReservation } from "../../../features/reservation/reservationInterface"
import { findReservation } from "../../../helpers/reservation"
import Payment from "../../../components/kiosk/Payment"
import { selectConfig } from "../../../features/config/configSlice"

export default function ReservationDetails() {
  let { id } = useParams()
  const config = useSelector(selectConfig)
  const reservation = useSelector(selectReservation)
  const openBills = useSelector(selectOpenBills)

  const [reservationDetails, setReservationDetails] = useState<IReservation>()
  const navigate = useNavigate()

  useEffect(() => {
    if (id !== undefined) {
      const r = findReservation(id as string)
      if (r !== undefined) {
        setReservationDetails(r)
      }
    }
  }, [id, reservation.Reservations])

  return (
    <>
      <div className="flex-grow flex justify-center items-center flex-col w-full">
        <div className="text-h1 text-center">
          Hi
          {reservationDetails?.Customers.map((customer, index) => (
            <span key={index}>
              {index === 1 ? " and " : " "}
              {customer.FirstName}
            </span>
          ))}
          , <br />
          You’re here. Finally!
        </div>
        <div className="mt-6 mb-6 w-full">
          <div className="bg-white bg-opacity-75 rounded-xl p-4 grid grid-cols-2 gap-y-6 w-full">
            <div>
              <span className="text-gray-600">Confirmation number</span>
              <br />
              {reservationDetails?.ReservationNumber}
            </div>
            <div>
              <span className="text-gray-600">Room type</span>
              <br />
              {reservationDetails?.ResourceCategoryName}
            </div>
            <div>
              <span className="text-gray-600">Arrival</span>
              <br />
              {reservationDetails?.CheckInAt}
            </div>
            <div>
              <span className="text-gray-600">Departure</span>
              <br />
              {reservationDetails?.CheckOutAt}
            </div>
            <div>
              <span className="text-gray-600">Travellers</span>
              <br />
              {reservationDetails?.PersonCount}
            </div>
          </div>
        </div>

        <div className="w-full">
          {openBills !== null && reservationDetails !== undefined && (
            <Payment
              openBills={openBills}
              minimalAmount={0.1}
              callBack={() =>
                navigate(
                  "/kiosk/check-in/reservation-details/" +
                    reservationDetails.ReservationId,
                )
              }
            />
          )}
          
          {openBills?.Total?.Value !== undefined && openBills?.Total?.Value <= 0.1 && (
            <div className="mt-4">
              <Button
                link="/kiosk/check-in/customer-form/0"
                text="Check your details"
                suffix={<ArrowSmall />}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}
