/**
 *
 * Base helpers
 * Here you can find helpers that do not require a seperate file
 */

import { NavigateFunction } from "react-router-dom";
import { IPlace } from "../features/mapScreen/mapScreenInterface";

export const routeOut: { navigate: NavigateFunction | null } = {
  navigate: null,
};

export const navigateOut = (path: string) => {
  if(routeOut.navigate !== null){
    routeOut.navigate(path)
  }
}

// Upper case first letter
export function ucFirst(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
// Lower case first letter
export function lcFirst(string: string) {
  return string.charAt(0).toLowerCase() + string.slice(1)
}

export function formatStudlyCase(object: object) {
  return Object.fromEntries(Object.entries(object).map(([k, v]) => {
    return [lcFirst(k), v]
  }));
}

export const mergePlaces = (arr: IPlace[][]) => {
  let result: IPlace[] = []
  arr.forEach((placesArray) => {
    placesArray.forEach((item: IPlace) => {
      const exist = result.find(
        (place: IPlace) => place.GoogleId === item.GoogleId,
      )
      if (exist === undefined) {
        result = [...result, item]
      }
    })
  })
  return result
}
