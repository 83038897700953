import { useSelector } from "react-redux";
import { selectMapScreen } from "../../features/mapScreen/mapScreenSlice";
import { AnimatePresence, motion } from "framer-motion";

export function MapOverlay() {
	const mapScreen = useSelector(selectMapScreen);

	return (
		<AnimatePresence>
			{mapScreen.State === "CityHost" && (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					className="absolute inset-0 bg-cityhub-600/75"
				></motion.div>
			)}
		</AnimatePresence>
	);
}
