import Button from "../../../components/button/Button"
import ArrowSmall from "../../../components/svg/ArrowSmall"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import {
  selectActiveReservation,
  selectOpenBills,
} from "../../../features/reservation/reservationSlice"
import Payment from "../../../components/kiosk/Payment"

export default function OpenBills() {
  const openBills = useSelector(selectOpenBills)
  const navigate = useNavigate()
  const activeReservation = useSelector(selectActiveReservation)

  return (
    <>
      {activeReservation !== undefined && (
        <>
        <div className="flex-grow flex justify-center items-center flex-col w-3/4">
          <div className="text-h1 text-center">
            These have been your consumptions during your stay. 
          </div>
          <div className="mt-12 w-full">
            <Payment
              openBills={activeReservation.OpenBills}
              minimalAmount={0}
              callBack={() => navigate("/kiosk/check-out/feedback")}
              />
          </div>
        </div>
          {activeReservation.OpenBills.Total.Value === 0 && (
            <div className="h-48 flex justify-between items-center w-3/4 text-h3 text-center">
              <Button
                link={`/kiosk/check-out/feedback`}
                text="Next"
                suffix={<ArrowSmall />}
                />
            </div>
          )}
        </>
      )}
    </>
  )
}
