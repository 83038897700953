import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { IApp, ILoadingOverlay, ISnackbar } from "./appInterface"
import moment from "moment"

const initialState: IApp = {
  snackbar: {
    message: "",
    type: "success",
    position: "top-center",
    timestamp: "",
  },
  loadingOverlay: {
    show: false,
    message: "",
    timestamp: 0,
  },
}

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setSnackbar: (
      state,
      action: PayloadAction<{
        message: ISnackbar["message"]
        type: ISnackbar["type"]
        position: ISnackbar["position"]
      }>,
    ) => {
      state.snackbar.message = action.payload.message
      state.snackbar.type = action.payload.type
      state.snackbar.position = action.payload.position
      state.snackbar.timestamp = moment().toISOString()
    },
    clearSnackbarMessage: (state) => {
      state.snackbar.message = ""
    },
    setLoadingOverlay: (
      state,
      action: PayloadAction<{
        show: ILoadingOverlay["show"],
        message: ILoadingOverlay["message"]
        seconds?: number
      }>,
    ) => {
      state.loadingOverlay.show = action.payload.show
      state.loadingOverlay.message = action.payload.message
      state.loadingOverlay.timestamp = moment().valueOf() + (action.payload.seconds ? (action.payload.seconds * 1000) :  2000)
    },
    clearLoadingOverlay: (state) => {
      state.loadingOverlay.show = false
      state.loadingOverlay.message = ""
      state.loadingOverlay.timestamp = 0
    },
  },
})

export const { setSnackbar, clearSnackbarMessage, setLoadingOverlay, clearLoadingOverlay } = appSlice.actions

// Selectors
export const selectApp = (state: RootState) => state.app
export const selectSnackbar = (state: RootState) => state.app.snackbar
export const selectLoadingOverlay = (state: RootState) => state.app.loadingOverlay

export default appSlice.reducer
