import { FormEvent, useEffect, useState } from "react"
import { InputField } from "../form/InputField"
import { get } from "../../helpers/api"
import { useDispatch, useSelector } from "react-redux"
import {
  clearConfiguration,
  selectConfig,
  setApiKey,
  setConfiguration,
  clearApiKey,
} from "../../features/config/configSlice"
import SubmitSuffix from "../button/SubmitSuffix"
import { showSnackbar } from "../../helpers/notification"
import ArrowPath from "../svg/ArrowPath"
import Button from "../button/Button"
import XMark from "../svg/XMark"

type IConfigForm = {
  onCancel: () => void
}

export default function ConfigForm(props: IConfigForm) {
  const { onCancel } = props
  const dispatch = useDispatch()
  const config = useSelector(selectConfig)
  const [apiKeyInput, setApiKeyInput] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [access, setAccess] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)

  const formCheckPassword = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    checkPassword()
  }

  const checkPassword = () => {
    if (password === "Hookm3up") {
      setAccess(true)
    } else {
      showSnackbar("Incorrect password", "error", "top-center")
    }
  }

  const formRefreshConfig = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleSubmit()
  }

  const handleSubmit = () => {
    dispatch(setApiKey(apiKeyInput))
    refreshConfig()
  }

  const refreshConfig = () => {
    setLoading(true)
    dispatch(clearConfiguration())
    get({ url: "configuration", params: {} })
      .then((response) => {
        if (response.data && response.data.Success) {
          showSnackbar(response.data.Message, "success", "top-center")
          dispatch(
            setConfiguration({
              Property: response.data.Property,
              Kiosk: response.data.Kiosk,
            }),
          )
        } else {
          dispatch(setApiKey(""))
          showSnackbar("Incorrect api key", "error", "top-center")
        }
        setLoading(false)
        setErrorMessage("")
      })
      .catch((error) => {
        setErrorMessage(error.response?.statusText)
        setLoading(false)
      })
  }

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
          <div className="relative bg-white text-black transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
            <div className="p-4">
              <div className="flex items-start">
                <div className="mt-3 flex-grow text-left">
                  {!access && (
                    <div>
                      <form
                        onSubmit={(e) => {
                          formCheckPassword(e)
                        }}
                      >
                        <h3 className="mb-2 text-xl leading-7">
                          Enter password
                        </h3>
                        <InputField
                          type="password"
                          name="password"
                          placeholder="Password"
                          classes="border border-gray-300"
                          defaultValue={""}
                          autoFocus={true}
                          onChange={(e) => setPassword(e.target.value)}
                          suffix={
                            <SubmitSuffix
                              onSubmit={() => checkPassword()}
                              loading={loading}
                            />
                          }
                        />
                        {errorMessage !== "" && (
                          <p className="mt-2 text-red-600">{errorMessage}</p>
                        )}
                      </form>
                    </div>
                  )}
                  {access && config.ApiKey === "" && (
                    <div>
                      <form
                        onSubmit={(e) => {
                          formRefreshConfig(e)
                        }}
                      >
                        <h3 className="mb-2 text-xl leading-7">
                          Enter api key
                        </h3>
                        <InputField
                          type="text"
                          name="api-key"
                          placeholder="Api key"
                          classes="border border-gray-300"
                          defaultValue={apiKeyInput}
                          autoFocus={true}
                          onChange={(e) => setApiKeyInput(e.target.value)}
                          suffix={
                            <SubmitSuffix
                              onSubmit={() => handleSubmit()}
                              loading={loading}
                            />
                          }
                        />
                        {errorMessage !== "" && (
                          <p className="mt-2 text-red-600">{errorMessage}</p>
                        )}
                        <div className="mt-4 text-sm text-gray-400">
                          Chapi: {process.env.VITE_CHAPI_URL} <br />
                          Mode: {import.meta.env.MODE}
                        </div>
                      </form>
                    </div>
                  )}
                  {access && config.ApiKey !== "" && (
                    <>
                      <h3 className="mb-2 text-xl leading-7">
                        Configuration: {config?.Kiosk?.Name}
                      </h3>
                      <div className="mt-4 grid grid-cols-4 gap-4 text-sm">
                        <div className="col-span-1">Api url</div>
                        <div className="break-words col-span-3">
                          {import.meta.env.VITE_CHAPI_URL}
                        </div>
                        <div className="col-span-1">Property</div>
                        <div className="break-words col-span-3">
                          {config?.Property?.Name}
                        </div>
                        <div className="col-span-1">Abbreviation</div>
                        <div className="break-words col-span-3">
                          {config?.Property?.Abbreviation}
                        </div>
                        <div className="col-span-1">Lock system</div>
                        <div className="break-words col-span-3">
                          {config?.Property?.LockSystem}
                        </div>
                        <div className="col-span-1">Lock system url</div>
                        <div className="break-words col-span-3">
                          {config?.Property?.LockApiUrl}
                        </div>
                        <div className="col-span-1">Booking Module Url</div>
                        <div className="break-words col-span-3">
                          {config?.Property?.BookingModuleUrl}
                        </div>
                        <div className="col-span-1">PosTerminalId</div>
                        <div className="break-words col-span-3">
                          {config?.Kiosk?.PosTerminalId}
                        </div>
                        <div className="col-span-1">Language</div>
                        <div className="break-words col-span-1">
                          {config?.Property?.Language}
                        </div>
                        <div className="col-span-1">Currency</div>
                        <div className="break-words col-span-1">
                          {config?.Property?.Currency}
                        </div>
                        <div className="col-span-1">EncoderNumber</div>
                        <div className="break-words col-span-3">
                          {config?.Kiosk?.EncoderNumber}
                        </div>
                        <div className="col-span-1">Pre auth</div>
                        <div className="break-words col-span-1">
                          {config?.Property?.PreAuthEnabled
                            ? "Enabled"
                            : "Disabled"}
                        </div>
                        <div className="col-span-1">Pre auth cashlist</div>
                        <div className="break-words col-span-1">
                          {config?.Property?.PreAuthCashlist
                            ? "Enabled"
                            : "Disabled"}
                        </div>
                        <div className="col-span-1">Screen width</div>
                        <div className="break-words col-span-1">
                          {screen.width}px
                        </div>
                        <div className="col-span-1">Screen height</div>
                        <div className="break-words col-span-1">
                          {screen.height}px
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            {access && config.ApiKey !== "" && (
              <div className="px-4 py-3 sm:flex justify-between items-center">
                <Button
                  type="button"
                  onClick={() => {
                    dispatch(clearApiKey())
                    dispatch(clearConfiguration())
                    localStorage.clear()
                  }}
                  text="Remove api key"
                  style="error"
                  size="small"
                />
                <Button
                  type="button"
                  onClick={() => {
                    refreshConfig()
                  }}
                  text="Refresh api config"
                  style="primary"
                  size="small"
                />
              </div>
            )}
            <div className="px-4 py-3 sm:flex justify-between items-center sm:px-6 bg-black">
              <div className="text-sm text-white">Version: 2.0.2</div>
              <Button
                type="button"
                onClick={() => {
                  setAccess(false)
                  onCancel()
                }}
                text="Close"
                style="contrast"
                size="small"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
