import Button from "../../../components/button/Button"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { selectActiveReservation } from "../../../features/reservation/reservationSlice"
import { post } from "../../../helpers/api"
import {
  clearLoadingOverlay,
  setLoadingOverlay,
} from "../../../features/application/appSlice"
import { showSnackbar } from "../../../helpers/notification"

export default function UpdateNewsletter() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const activeReservation = useSelector(selectActiveReservation)

  const handleSubmit = (consent: string) => {
    dispatch(clearLoadingOverlay())
    if (activeReservation) {
      dispatch(
        setLoadingOverlay({
          show: true,
          message: "Processing your preference!",
          seconds: 10,
        }),
      )
      post({
        url: "customer/newsletter/update",
        params: {
          reservationId: activeReservation.ReservationId,
          consent: consent,
        },
      })
        .then((response) => {
          if (response.data && response.data.Success) {
            showSnackbar(response.data.Message, "success", "top-center")
            // dispatch(clearLoadingOverlay())
            navigate("/kiosk/check-out/confirmation")
          } else {
            dispatch(clearLoadingOverlay())
            showSnackbar(response.data.Message, "error", "top-center")
          }
        })
        .catch((error) => {
          dispatch(clearLoadingOverlay())
          showSnackbar(error.response?.data.message, "error", "top-center")
        })
    }
  }

  return (
    <div className="flex justify-center items-center flex-col h-screen">
      <div className="flex-grow flex justify-center items-center flex-col w-3/4">
        <div className="text-h1 text-center">
          Would you like to subscribe to our newsletter?
        </div>
        <div className="mt-12 w-3/4 flex justify-center space-x-8">
          <Button
            type="button"
            onClick={() => {
              handleSubmit("false")
            }}
            text="No"
            size="small"
            style="error"
          />
          <Button
            type="button"
            onClick={() => {
              handleSubmit("true")
            }}
            text="Yes"
            size="small"
          />
        </div>
      </div>
    </div>
  )
}
