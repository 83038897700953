import { useSelector } from "react-redux"
import { selectConfig } from "../../../features/config/configSlice"
import { selectReservation } from "../../../features/reservation/reservationSlice"
import SelectReservationTile from "../../../components/kiosk/SelectReservationTile"

export default function SelectReservation() {
  const config = useSelector(selectConfig)
  const reservation = useSelector(selectReservation)

  return (
    <div className="flex-grow flex justify-center items-center flex-col w-full">
      <div className="text-h1 text-center">
        Select{" "}
        {config.Property?.Abbreviation === "CPH" ? "an accomodation" : "a Hub"}{" "}
        to check in.
      </div>

      <div
        className={`mt-12 grid gap-12 grid-cols-${
          reservation.Reservations.length === 1 ? "1 w-3/4" : "2 w-full"
        }`}
      >
        {reservation.Reservations.map((reservation, index) => {
          return (
            <SelectReservationTile
              index={index}
              reservation={reservation}
              key={index}
            />
          )
        })}
      </div>
    </div>
  )
}
