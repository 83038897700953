import {
  InputHTMLAttributes,
  createRef,
  useEffect,
} from "react"
import Loading from "../svg/Loading"

interface IInputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  suffix?: any
  classes?: string
  inputClasses?: string
  defaultValue?: any
  errorMessage?: string
  loading?: boolean
}

export const InputField = (props: IInputFieldProps) => {
  const inputRef = createRef<HTMLInputElement>()
  const {
    classes = "",
    inputClasses = "",
    type,
    name,
    placeholder,
    disabled,
    label = undefined,
    suffix = undefined,
    defaultValue,
    value,
    autoFocus = false,
    loading = undefined,
    onChange,
    onFocus,
    onBlur,
    errorMessage = "",
  } = props

  useEffect(() => {
    if (value !== undefined && inputRef.current !== null) {
      inputRef.current.value = value as string
    }
  }, [value])

  return (
    <div>
      <div className="relative">
        <div
          className={`w-full  rounded-lg py-2 px-4 ${
            suffix !== undefined ? "pr-16" : ""
          } ${
            disabled !== undefined && disabled ? "bg-gray-100" : "bg-white"
          } flex text-gray-900 ${classes}`}
        >
          <div className="flex-1">
            {label !== undefined && (
              <label htmlFor={name} className="text-gray-600 text-sm">
                {label}
              </label>
            )}
            <input
              ref={inputRef}
              className={`text-gray-900 rounded-lg outline-none block w-full ${inputClasses}`}
              type={type}
              name={name}
              placeholder={placeholder}
              disabled={disabled}
              defaultValue={defaultValue}
              autoFocus={autoFocus}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </div>
        </div>
        {suffix !== undefined && suffix}
        {loading && <Loading className="absolute top-2 right-2 w-6 h-6 text-black animate-loading"/>}
      </div>
      {errorMessage && (
        <div className="mt-2 px-4 text-red-600 text-sm text-right">
          {errorMessage}
        </div>
      )}
    </div>
  )
}
