import { useSelector } from "react-redux";
import { selectMapScreen } from "../../features/mapScreen/mapScreenSlice";
import CityHostSlider from "./CityHostSlider";
import Loading from "../svg/Loading";
import { AnimatePresence, motion } from "framer-motion";

export default function CityHost() {
	const mapScreen = useSelector(selectMapScreen);

	return (
		<AnimatePresence>
			{mapScreen.State === "CityHost" && (
				<motion.div
					initial={{ x: -300, opacity: 0 }}
					animate={{ x: 0, opacity: 1 }}
					exit={{ x: -300, opacity: 0 }}
					className="absolute top-4 bottom-4 left-4 z-10 aspect-[20/35]"
				>
					{mapScreen.CityHost === null ||
						(mapScreen.CityHost.Slides.length === 0 && (
							<div className="flex justify-center items-center h-screen">
								<Loading className="w-48 h-48 animate-loading text-cityhub-500" />
							</div>
						))}
					{mapScreen.CityHost !== null &&
						mapScreen.CityHost.Slides.length > 0 && (
							<div className="absolute inset-0 rounded-md overflow-hidden bg-cityhub-500">
								<CityHostSlider />
							</div>
						)}
				</motion.div>
			)}
		</AnimatePresence>
	);
}
