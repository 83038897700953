import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { IConfigState, IProperty, IKiosk } from "./configInterface";


const initialState: IConfigState = {
  Loaded: false,
  ApiKey: "",
  ApiUuid: "",
  Property: null,
  Kiosk: null
}

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setApiKey: (state, action: PayloadAction<IConfigState["ApiKey"]>) => {
      state.ApiKey = action.payload;
    },
    clearApiKey: (state) => {
      state.ApiKey = "";
    },
    setApiUuid: (state, action: PayloadAction<IConfigState["ApiUuid"]>) => {
      state.ApiUuid = action.payload;
    },
    clearApiUuid: (state) => {
      state.ApiUuid = "";
    },
    setConfiguration: (state, action: PayloadAction<{Property: IProperty, Kiosk: IKiosk}>) => {
      state.Loaded = true;
      state.Property = action.payload.Property;
      state.Kiosk = action.payload.Kiosk;
    },
    clearConfiguration: (state) => {
      state.Loaded = false;
      state.Property = null;
      state.Kiosk = null;
    },
  },

});

export const { setApiKey, clearApiKey, setApiUuid, clearApiUuid, setConfiguration, clearConfiguration } = configSlice.actions;


// Selectors
export const selectConfig = (state: RootState) => state.config;

export default configSlice.reducer
