import Button from "../../../components/button/Button"
import ArrowSmall from "../../../components/svg/ArrowSmall"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { selectActiveReservation } from "../../../features/reservation/reservationSlice"
import Payment from "../../../components/kiosk/Payment"

export default function OpenBillsCustomer() {
  const navigate = useNavigate()
  const activeReservation = useSelector(selectActiveReservation)

  return (
    <div className="flex-grow flex justify-center items-center flex-col w-full">
      {activeReservation !== undefined && (
        <div className="flex-grow flex justify-center items-center flex-col w-3/4">
          <div className="text-h1 text-center">
            There is an amount due.
            <br />
            Please pay before check in.
          </div>
          <div className="mt-12 w-full">
            <Payment
              openBills={activeReservation.OpenBills}
              minimalAmount={0.1}
              callBack={() => {
                if (activeReservation?.ResourceCategoryName === "Room") {
                  navigate("/kiosk/check-in/select-resource")
                } else {
                  navigate("/kiosk/check-in/select-resource-video")
                }
              }}
            />
          </div>
        </div>
      )}
      {activeReservation !== undefined && activeReservation.OpenBills.Total.Value <= 0 && (
        <div className="mt-12 flex justify-between items-center w-3/4 text-h3 text-center">
          <Button
            link={`/kiosk/check-in/customer-form/0`}
            text="Next"
            suffix={<ArrowSmall />}
          />
        </div>
      )}
    </div>
  )
}
