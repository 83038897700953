import { useEffect } from "react"
import Button from "../../../components/button/Button"
import ArrowSmall from "../../../components/svg/ArrowSmall"
import { resetReservations } from "../../../helpers/reservation"

export default function ConfirmationExpense() {
  useEffect(() => {
    setTimeout(() => {
      resetReservations()
    }, 5000)
  }, [])

  return (
    <div className="flex-grow flex justify-center items-center flex-col w-full">
      <div className="flex-grow flex justify-center items-center flex-col w-3/4">
        <div className="text-h1 text-center">Thanks!</div>
        <div className="mt-12 text-center">
          <div className="text-h2">...for checking your expenses.</div>
        </div>
      </div>
      <div className="h-48 flex flex-col items-center w-3/4 text-h3 text-center space-y-6">
        <Button
          type="button"
          onClick={() => resetReservations()}
          text="Finish"
          suffix={<ArrowSmall />}
        />
      </div>
    </div>
  )
}
