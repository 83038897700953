import { configureStore, ThunkAction, Action, combineReducers } from "@reduxjs/toolkit"
import appReducer from "../features/application/appSlice"
import chatRoomReducer from "../features/chatRoom/chatRoomSlice"
import configReducer from "../features/config/configSlice"
import mapScreenReducer from "../features/mapScreen/mapScreenSlice"
import reservationReducer from "../features/reservation/reservationSlice"

import storage from 'redux-persist/lib/storage';
import { persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER, } from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
}

const reducers = combineReducers({
  app: appReducer,
  chatRoom: chatRoomReducer,
  config: configReducer,
  mapScreen: mapScreenReducer,
  reservation: reservationReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  // middleware: [thunk]
});

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
