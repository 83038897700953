import { useDispatch } from "react-redux"
import { ISnackbar } from "../features/application/appInterface"
import { setSnackbar } from "../features/application/appSlice"
import { store } from "../app/store"
import { IChatRoom } from "../interfaces/chatRoomInterface"
import { post } from "./api"

// Show snackbar
export const showSnackbar = (
  message: ISnackbar["message"],
  type: ISnackbar["type"],
  position: ISnackbar["position"],
) => {
  store.dispatch(setSnackbar({ message, type, position }))
}

export const sendFCMMessage = (
  token: IChatRoom["notificationToken"],
  title: string,
  message: string,
) => {
  // Send push notification
  if (token.length > 0) {
    post({
      url: "notification/fcm-token",
      params: {
        token: token,
        title: title,
        message: message,
      },
    })
      .then((response) => {
        showSnackbar(response.data.Message, "success", "top-center")
      })
      .catch((e) => console.log(e))
  }
}
