import { store } from "../app/store"
import {
  clearLoadingOverlay,
  setLoadingOverlay,
} from "../features/application/appSlice"
import { clearApiUuid } from "../features/config/configSlice"
import { IReservation } from "../features/reservation/reservationInterface"
import { clearReservations } from "../features/reservation/reservationSlice"
import { navigateOut } from "./base"

export function findReservation(
  Id: IReservation["ReservationId"],
): IReservation | undefined {
  const { reservation } = store.getState()
  return reservation.Reservations.find((r) => r.ReservationId === Id)
}

export function findNextReservation(): IReservation | undefined {
  const { reservation } = store.getState()
  return reservation.Reservations.find((r) => r.State === "confirmed")
}

export function getIndex(
  reservations: IReservation[],
  Id: IReservation["ReservationId"],
) {
  return reservations.findIndex((r) => r.ReservationId === Id)
}

export function getTotalPersons() {
  const { reservation } = store.getState()
  const total = reservation.Reservations.reduce((acc, reservation) => {
    return acc + reservation.PersonCount
  }, 0)
  return total
}

export function resetReservations() {
  store.dispatch(clearApiUuid())
  store.dispatch(clearReservations())
  store.dispatch(
    setLoadingOverlay({
      show: true,
      message: "",
      seconds: 1,
    }),
  )
  
  setTimeout(() => {
    navigateOut("/kiosk")
    store.dispatch(clearLoadingOverlay())
  }, 1000)
}
