import { useSelector } from "react-redux";
import { selectMapScreen } from "../../features/mapScreen/mapScreenSlice";
import { selectChatRoom } from "../../features/chatRoom/chatRoomSlice";
import XMark from "../svg/XMark";
import { toggleConnect } from "../../helpers/mapScreen";

export function MyPlaces() {
	const mapScreen = useSelector(selectMapScreen);
	const chatRoom = useSelector(selectChatRoom);

	const disconnect = () => {
		toggleConnect("");
	};

	return (
		<>
			{mapScreen.ChatRoomDocId != "" && (
				<div
					className="flex justify-start bg-black p-2 rounded-md text-white text-sm flex items-center justify-between cursor-pointer"
					onClick={() => {
						disconnect();
					}}
				>
					<span>{"Connected to " + chatRoom.Owner.FirstName}</span>
					<XMark className="w-5 h-5 ml-2" />
				</div>
			)}
		</>
	);
}
