import { SVGProps } from "react"

export default function Hub(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 60 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-24 h-24"
      {...props}
    >
      <path
        d="M4.73717 34.7279C6.86635 34.7279 8.597 32.8652 8.597 30.5724V8.58021H55.2643C57.3935 8.58021 59.1241 6.71608 59.1241 4.42472C59.1241 2.13194 57.3935 0.269226 55.2643 0.269226H0.875977V30.5739C0.875977 32.8666 2.60935 34.7308 4.73717 34.7308V34.7279Z"
        fill="currentColor"
      />
    </svg>
  )
}
