import Heart from "../svg/Heart"
import MusicalNote from "../svg/MusicalNote"
import Scale from "../svg/Scale"
import Sun from "../svg/Sun"

export default function SelectSceneTile({
  type,
  onSelectScene
}: {
  type: "love" | "groovy" | "sunset" | "nature",
  onSelectScene: (name: string, scene: number) => void
}) {

  const types: {[key: string]: {name: string, number: number, icon: JSX.Element}} = {
    "groovy": {
      name: "Groovy",
      number: 5,
      icon: <MusicalNote className="w-12 h-12" />
    },
    "love": {
      name: "Love",
      number: 2,
      icon: <Heart className="w-12 h-12" />
    },
    "sunset": {
      name: "Sunset",
      number: 4,
      icon: <Sun className="w-12 h-12" />
    },
    "nature": {
      name: "Nature",
      number: 3,
      icon: <Scale className="w-12 h-12" />
    },
  }

  return (
    <div className="flex flex-col p-6 bg-white bg-opacity-75 rounded-xl cursor-pointer" onClick={() => onSelectScene(types[type].name, types[type].number)}>
      <div className="flex items-center justify-center">
        {types[type].icon}
      </div>
      <div className="text-center text-xl mt-4 font-semibold">
        {types[type].name}
      </div>
    </div>
  )
}
