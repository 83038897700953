import Button from "../../../components/button/Button"
import ArrowSmall from "../../../components/svg/ArrowSmall"
import { useDispatch, useSelector } from "react-redux"
import { selectActiveReservation } from "../../../features/reservation/reservationSlice"
import {
  clearLoadingOverlay,
  setLoadingOverlay,
} from "../../../features/application/appSlice"
import { useEffect, useState } from "react"
import { post } from "../../../helpers/api"
import { showSnackbar } from "../../../helpers/notification"
import { resetReservations } from "../../../helpers/reservation"

export default function ConfirmationCheckOut() {
  const dispatch = useDispatch()
  const [success, setSuccess] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const activeReservation = useSelector(selectActiveReservation)

  useEffect(() => {
    handleSubmit()
  }, [])

  const handleSubmit = () => {
    if (activeReservation) {
      dispatch(
        setLoadingOverlay({
          show: true,
          message: "We're checking you out",

          seconds: 60,
        }),
      )
      post({
        url: "reservation/check-out",
        params: {
          reservationId: activeReservation.ReservationId,
        },
      }).then((response) => {
        if (response.data && response.data.Success) {
          setSuccess(true)
          showSnackbar(response.data.Message, "success", "top-center")
          setErrorMessage("")
          dispatch(clearLoadingOverlay())
        } else {
          setSuccess(false)
          // showSnackbar(response.data.Message, "error", "top-center")
          setErrorMessage(response.data.Message)
          dispatch(clearLoadingOverlay())
        }
      })
    }
  }

  return (
    <div className="flex justify-center items-center flex-col h-screen w-full">
      <div className="flex justify-center items-center flex-col w-full">
        {success === true && (
          <>
            {activeReservation && activeReservation?.Customers.length === 1 && (
              <div className="text-h1 text-center">
                {activeReservation?.Customers[0].FirstName}, you're checked out
                now.
              </div>
            )}
            {activeReservation && activeReservation?.Customers.length >= 2 && (
              <div className="text-h1 text-center">
                {activeReservation?.Customers.map((customer, i) => {
                  if (i === 0) {
                    return <span key={i}>{customer.FirstName}</span>
                  } else if (i === activeReservation?.Customers.length - 1) {
                    return <span key={i}> and {customer.FirstName}</span>
                  } else {
                    return <span key={i}>, {customer.FirstName}</span>
                  }
                })}
                , you're {activeReservation?.Customers.length > 2 ? "(all)" : "(both)"} checked out now.
              </div>
            )}

            <div className="mt-12 text-center">
              <div className="text-h2">
                <div>
                  Thanks for staying over! <br />
                  Hope to see you next time ❤️
                </div>
              </div>
            </div>
          </>
        )}

        {success === false && errorMessage !== "" && (
          <>
            <div className="text-h1 text-center">An error occurred</div>
            <div className="mt-12 text-center">
              <div className="text-h2">{errorMessage}</div>
            </div>
          </>
        )}
      </div>
      <div className="mt-12 w-full">
        <Button
          type="button"
          onClick={() => resetReservations()}
          text="Close"
          suffix={<ArrowSmall />}
        />
        {success === false && errorMessage !== "" && (
          <div className="mt-6">
            <Button
              type="button"
              onClick={() => resetReservations()}
              text="Try again"
              style="secondary"
              suffix={<ArrowSmall />}
            />
          </div>
        )}
      </div>
    </div>
  )
}
