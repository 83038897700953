import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	MsSetSelectedPlace,
	selectMapScreen,
} from "../../features/mapScreen/mapScreenSlice";
import XMark from "../svg/XMark";
import { AnimatePresence, motion } from "framer-motion";
import Button from "../button/Button";
import DevicePhoneMobile from "../svg/DevicePhoneMobile";
import { sendPlace } from "../../helpers/mapScreen";
import { showSnackbar } from "../../helpers/notification";
import Star from "../svg/Star";
import { AddToMyPlaces } from "./AddToMyPlaces";
import { get } from "../../helpers/api";

export function SelectedPlace() {
	const dispatch = useDispatch();
	const [show, setShow] = useState<boolean>(false);
	const mapScreen = useSelector(selectMapScreen);

	useEffect(() => {
		if (mapScreen.SelectedPlace !== null) {
			setShow(true);
		} else {
			setShow(false);
		}
	}, [mapScreen.SelectedPlace]);

	return (
		<>
			<AnimatePresence>
				{show && mapScreen.SelectedPlace && (
					<motion.div
						initial={{ x: -300, opacity: 0 }}
						animate={{ x: 0, opacity: 1 }}
						exit={{ x: -300, opacity: 0 }}
						className="relative w-[300px] flex flex-col justify-center items-center bg-cityhub-500 rounded-md text-black shadow-2xl"
					>
						{mapScreen.SelectedPlace.Photo !== null && (
							<img
								src={mapScreen.SelectedPlace.Photo}
								alt=""
								className="object-cover w-full aspect-[4/3] rounded-t-md "
							/>
						)}
						{mapScreen.SelectedPlace.Photo === null && (
							<div className="object-cover w-full aspect-[4/3] rounded-t-md "></div>
						)}
						<div className="flex space-x-2 p-4 w-full">
							<div className="flex-1">
								<div className="flex justify-between">
									<div className="flex-1">{mapScreen.SelectedPlace.Name}</div>
									<div className="flex items-center">
										<Star className="w-4 h-4" />
										<div className="ml-1 text-sm">
											{mapScreen.SelectedPlace.Rating}
										</div>
									</div>
								</div>
								<div className="text-sm text-gray-800">
									{mapScreen.SelectedPlace.Address}
								</div>
							</div>
							{mapScreen.ChatRoomDocId != "" && (
								<div className="text-right">
									<AddToMyPlaces place={mapScreen.SelectedPlace} />
								</div>
							)}
						</div>
						<div className="absolute -top-2 -right-2 bg-black rounded-full p-2 text-white cursor-pointer">
							<XMark onClick={() => dispatch(MsSetSelectedPlace(null))} />
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</>
	);
}
