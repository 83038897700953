import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { showSnackbar } from "../../../helpers/notification"
import {
  setActiveReservation,
  setOpenBills,
  setReservations,
} from "../../../features/reservation/reservationSlice"
import ScanWristband from "../../../components/kiosk/ScanWristband"

export default function CheckExpense() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  /**
   * Get customer and reservation from ScanWristband
   * Navigate to open bills
   */
  const handleSubmit = (response: any) => {
    showSnackbar(response.data.Message, "success", "top-center")
    dispatch(setReservations(response.data.Reservations))
    dispatch(setActiveReservation(response.data.Reservations[0].ReservationId))

    if (response.data.Reservations[0].PreAuth) {
      navigate("/kiosk/check-expense/pre-auth")
    } else if (response.data.OpenBills) {
      navigate("/kiosk/check-expense/open-bills")
    }
  }

  return (
    <div className="flex justify-center items-center flex-col h-screen">
      <div className="flex-grow flex justify-center items-center flex-col w-3/4">
        <div className="grid grid-cols-1 gap-12">
          <div className="text-h1 text-center">
            Please scan your wristband here.
          </div>
          <ScanWristband
            endpoint={"/customer/token/get-open-bills"}
            callBack={(token) => handleSubmit(token)}
          />
        </div>
      </div>
    </div>
  )
}
