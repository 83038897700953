import { IChatRoomState } from "../features/chatRoom/chatRoomInterface";
import { IChatRoom } from "../interfaces/chatRoomInterface";

/**
 *
 * Chat room helpers
 */
export const formatChatroom = (data: any):IChatRoomState => {

  const newChatRoom: IChatRoomState = {
    Id: data.id,
    Messages: data.messages,
    HotelCode: data.hotelCode,
    Status: data.status,
    Owner: {
      FirstName: data.owner.firstName,
      ProfilePicture: data.owner.profilePicture,
      Uuid: data.owner.uuid,
    },
    NotificationToken: data.notificationToken,
  };
  return newChatRoom;
}

export const convertChatRoom = (chatroom: IChatRoomState): IChatRoom => {
  const newChatRoom: IChatRoom = {
    id: chatroom.Id,
    messages: chatroom.Messages,
    hotelCode: chatroom.HotelCode,
    status: chatroom.Status,
    owner: {
      firstName: chatroom.Owner.FirstName,
      picture: chatroom.Owner.ProfilePicture,
      uuid: chatroom.Owner.ProfilePicture,
    },
    notificationToken: chatroom.NotificationToken,
  };
  return newChatRoom;
}
