import Button from "../../../components/button/Button"
import ArrowSmall from "../../../components/svg/ArrowSmall"
import { useSelector } from "react-redux"
import { selectActiveReservation } from "../../../features/reservation/reservationSlice"
import OpenBillsCard from "../../../components/kiosk/OpenBillsCard"
import { resetReservations } from "../../../helpers/reservation"
import { useNavigate } from "react-router-dom"

export default function PreAuthExpense() {
  const activeReservation = useSelector(selectActiveReservation)
  const navigate = useNavigate()

  return (
    <>
      {activeReservation !== undefined && (
        <>
          <div className="flex-grow flex justify-center items-center flex-col w-full">
            <div className="text-h1 text-center">
              These have been your consumptions during your stay.
            </div>
            <div className="mt-12 w-full">
              <OpenBillsCard openBills={activeReservation.OpenBills} />
            </div>
            <div className="mt-6 flex justify-between items-center w-full text-h3 text-center">
              <Button
                onClick={() => resetReservations()}
                type="button"
                text="Close"
                suffix={<ArrowSmall />}
              />
            </div>
            {activeReservation.OpenBills.Total.Value > 0 && (
              <div className="mt-6 flex justify-between items-center w-full text-h3 text-center">
                <Button
                  onClick={() => navigate("/kiosk/check-expense/open-bills")}
                  type="button"
                  text="Or pay now"
                  style="secondary"
                  suffix={<ArrowSmall />}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  )
}
