import { SVGProps } from "react"

export default function Room(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="60"
      height="50"
      viewBox="0 0 60 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_621_21)">
        <path
          d="M4.73717 34.7277C6.86635 34.7277 8.597 32.865 8.597 30.5722V8.58003H55.2643C57.3935 8.58003 59.1241 6.7159 59.1241 4.42454C59.1241 2.13176 57.3935 0.269043 55.2643 0.269043H0.875977V30.5737C0.875977 32.8664 2.60935 34.7306 4.73717 34.7306V34.7277Z"
          fill="black"
        />
      </g>
      <g clipPath="url(#clip1_621_21)">
        <path
          d="M55.2628 15.2723C53.1337 15.2723 51.403 17.135 51.403 19.4278V41.42H4.7357C2.6065 41.42 0.8759 43.2841 0.8759 45.5755C0.8759 47.8682 2.6065 49.731 4.7357 49.731H59.124V19.4263C59.124 17.1336 57.3907 15.2694 55.2628 15.2694V15.2723Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_621_21">
          <rect width="60" height="35" fill="white" />
        </clipPath>
        <clipPath id="clip1_621_21">
          <rect
            width="60"
            height="35"
            fill="white"
            transform="matrix(-1 0 0 -1 60 50)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
