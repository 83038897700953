import { useEffect, useState } from "react"
import ArrowsPointingIn from "./svg/ArrowsPointingIn"
import ArrowsPointingOut from "./svg/ArrowsPointingOut"

export function Fullscreen({
  className,
  callBack,
}: {
  className: string
  callBack: () => void
}) {
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false)

  useEffect(() => {
    function onFullscreenChange() {
      setIsFullscreen(Boolean(document.fullscreenElement))
    }
    document.addEventListener("fullscreenchange", onFullscreenChange)

    return () =>
      document.removeEventListener("fullscreenchange", onFullscreenChange)
  }, [])

  const setFullscreen = (enable: boolean) => {
    if (enable) {
      document.documentElement.requestFullscreen()
    } else {
      document.exitFullscreen()
    }
  }

  return (
    <>
      {!isFullscreen && (
        <ArrowsPointingOut
          onClick={() => {
            setFullscreen(true)
            callBack()
          }}
          className={className}
        />
      )}
      {isFullscreen && (
        <ArrowsPointingIn
          onClick={() => {
            setFullscreen(false)
            callBack()
          }}
          className={className}
        />
      )}
    </>
  )
}
