import Button from "../../../components/button/Button"
import ArrowSmall from "../../../components/svg/ArrowSmall"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import {
  selectOpenBills,
  selectReservations,
} from "../../../features/reservation/reservationSlice"
import { getTotalPersons } from "../../../helpers/reservation"
import Payment from "../../../components/kiosk/Payment"

export default function OpenBillsAccount() {
  const openBills = useSelector(selectOpenBills)
  const reservations = useSelector(selectReservations)
  const navigate = useNavigate()

  return (
    <>
      {openBills !== null && (
        <>
          <div className="flex-grow flex justify-center items-center flex-col w-3/4">
            <div className="text-h1 text-center">
              Hi {openBills?.AccountHolder}, <br />
              You’re here. Finally!
            </div>

            <div className="mt-12 bg-white bg-opacity-75 rounded-xl p-4 grid grid-cols-2 gap-y-6 w-full">
              <div>
                <span className="text-gray-600">Confirmation number(s)</span>
                <br />
                {reservations.map((reservation, i) => {
                  return (
                    <span key={i}>
                      {reservation?.ReservationNumber}
                      <br />
                    </span>
                  )
                })}
              </div>
              <div>
                <span className="text-gray-600">Room type(s)</span>
                <br />
                {reservations.map((reservation, i) => {
                  return (
                    <span key={i}>
                      {reservation?.ResourceCategoryName}
                      <br />
                    </span>
                  )
                })}
              </div>
              <div>
                <span className="text-gray-600">Arrival</span>
                <br />
                {reservations[0]?.CheckInAt}
              </div>
              <div>
                <span className="text-gray-600">Departure</span>
                <br />
                {reservations[0]?.CheckOutAt}
              </div>
              <div>
                <span className="text-gray-600">Travellers</span>
                <br />
                {getTotalPersons()}
              </div>
            </div>
            <div className="mt-6 w-full">
              <Payment
                openBills={openBills}
                minimalAmount={0.1}
                callBack={() => navigate("/kiosk/check-in/select-reservation")}
              />
            </div>
          </div>
        </>
      )}

      {openBills && openBills.Total.Value <= 0 && (
        <div className="mt-12 flex justify-between items-center w-3/4 text-h3 text-center">
          <Button
            link={`/kiosk/check-in/select-reservation`}
            text="Next"
            suffix={<ArrowSmall />}
          />
        </div>
      )}
    </>
  )
}
