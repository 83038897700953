import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { showSnackbar } from "../../../helpers/notification"
import { selectActiveReservation } from "../../../features/reservation/reservationSlice"
import SelectSceneTile from "../../../components/kiosk/SelectSceneTile"
import {
  clearLoadingOverlay,
  setLoadingOverlay,
} from "../../../features/application/appSlice"
import { post } from "../../../helpers/api"
import { ucFirst } from "../../../helpers/base"

export default function SelectScene() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const activeReservation = useSelector(selectActiveReservation)

  const handleSubmit = (name: string, scene: number) => {
    if (activeReservation) {
      dispatch(
        setLoadingOverlay({
          show: true,
          message: "Setting your hub in the " + name.toLowerCase() + " mood",
        }),
      )
      post({
        url: `reservation/set-scene`,
        params: {
          reservationId: activeReservation?.ReservationId,
          scene: scene,
        },
      })
        .then((response) => {
          dispatch(clearLoadingOverlay())
          if (response.data && response.data.Success) {
            navigate("/kiosk/check-in/scan-wristband/0")
          } else {
            dispatch(clearLoadingOverlay())
            showSnackbar(response.data.Message, "error", "top-center")
          }
        })
        .catch((error) => {
          showSnackbar(error.response?.data.message, "error", "top-center")
          dispatch(clearLoadingOverlay())
          dispatch(clearLoadingOverlay())
        })
    }
  }

  return (
    <div className="flex-grow flex justify-center items-center flex-col w-3/4">
      <div className="text-h1 text-center">
        {activeReservation?.ResourceCategoryName !== undefined && (
          <>
            Your {ucFirst(activeReservation?.ResourceCategoryName)}, your mood.{" "}
            <br />
            In what mood would you like to set your{" "}
            {ucFirst(activeReservation?.ResourceCategoryName)}?
          </>
        )}
      </div>

      <div className={`mt-12 grid gap-12 grid-cols-2 w-full`}>
        <SelectSceneTile type="love" onSelectScene={handleSubmit} />
        <SelectSceneTile type="groovy" onSelectScene={handleSubmit} />
        <SelectSceneTile type="sunset" onSelectScene={handleSubmit} />
        <SelectSceneTile type="nature" onSelectScene={handleSubmit} />
      </div>
    </div>
  )
}
