import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { showSnackbar } from "../../../helpers/notification"
import AccomodationTile from "../../../components/kiosk/AccomodationTile"
import { get, post } from "../../../helpers/api"
import {
  selectActiveReservation,
  selectReservation,
  setReservations,
} from "../../../features/reservation/reservationSlice"
import { IResources } from "../../../features/reservation/reservationInterface"
import Button from "../../../components/button/Button"
import {
  clearLoadingOverlay,
  setLoadingOverlay,
} from "../../../features/application/appSlice"

export default function SelectResource() {
  const [retry, setRetry] = useState<boolean>(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const reservation = useSelector(selectReservation)
  const activeReservation = useSelector(selectActiveReservation)
  const [resources, setResources] = useState<IResources>()
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    getAvailableResources()
  }, [reservation.ActiveReservation])

  const getAvailableResources = (refreshReservation: string = "true") => {
    if (activeReservation) {
      dispatch(
        setLoadingOverlay({
          show: true,
          message: "Checking rooms...",
          seconds: 5,
        }),
      )

      setRetry(false)
      setErrorMessage("")

      get({
        url: `resource/availability`,
        params: {
          reservationId: activeReservation?.ReservationId,
          refreshReservation: refreshReservation,
        },
      })
        .then((response) => {
          if (response.data && response.data.Success) {
            showSnackbar(response.data.Message, "success", "top-center")
            setResources(response.data.Resources)
          } else {
            showSnackbar(response.data.Message, "error", "top-center")
            setErrorMessage(response.data.Message)
            setRetry(true)
          }
          setTimeout(() => {
            dispatch(clearLoadingOverlay())
          }, 500)
        })
        .catch((error) => {
          dispatch(clearLoadingOverlay())
          showSnackbar(error.response?.Message, "error", "top-center")
        })
    }
  }

  const handleSubmit = (
    resourceId: string | null | undefined,
    resourceNumber: string | undefined,
    floorNumber: string | undefined,
    locked: boolean,
  ) => {
    if (locked) {
      navigate("/kiosk/check-in/select-scene")
    } else if (
      activeReservation &&
      typeof resourceId === "string" &&
      typeof resourceNumber === "string" &&
      typeof floorNumber === "string"
    ) {
      // Call API to save resource
      dispatch(
        setLoadingOverlay({
          show: true,
          message: "Saving your room details",
          seconds: 3,
        }),
      )
      post({
        url: "resource/update",
        params: {
          reservationId: activeReservation?.ReservationId,
          resourceId,
          resourceNumber,
          floorNumber,
        },
      })
        .then((response) => {
          if (response.data && response.data.Success) {
            showSnackbar(response.data.Message, "success", "top-center")
            dispatch(setReservations(response.data.Reservations))
            navigate("/kiosk/check-in/select-scene")
          } else {
            showSnackbar(response.data.Message, "error", "top-center")
          }
        })
        .catch((error) => {
          showSnackbar(error.response?.data.message, "error", "top-center")
        })
    }
  }

  return (
    <div className="flex-grow flex justify-center items-center flex-col w-full">
      <div className="flex-grow flex justify-center items-center flex-col w-3/4">
        <div className="text-h1 text-center">
          {resources && (
            <>
              {(activeReservation?.AssignedResourceLocked === true ||
                activeReservation?.ResourceCategoryName === "Room") && (
                <span>You'll stay in,..</span>
              )}
              {activeReservation?.AssignedResourceLocked === false &&
                activeReservation?.ResourceCategoryName !== "Room" && (
                  <span>Would you like a top or bottom hub?</span>
                )}
            </>
          )}
          {errorMessage && (
            <div>
              Please ask your CityHost.
              <br />
              <span className="text-h4">{errorMessage}</span>
            </div>
          )}
        </div>
        {resources !== undefined && (
          <div
            className={`grid grid-cols-${
              resources.Top && resources.Bottom ? "2" : "1"
            } gap-12 w-full mt-12`}
          >
            {resources.Top !== undefined && (
              <AccomodationTile
                resource={resources.Top}
                type="top"
                onClick={() => {
                  if (resources.Top !== undefined) {
                    handleSubmit(
                      resources.Top["Id"],
                      resources.Top["Number"],
                      resources.Top["FloorNumber"],
                      resources.Top["Locked"],
                    )
                  }
                }}
              />
            )}
            {resources.Bottom !== undefined && (
              <AccomodationTile
                resource={resources.Bottom}
                type="bottom"
                onClick={() => {
                  if (resources.Bottom !== undefined) {
                    handleSubmit(
                      resources.Bottom["Id"],
                      resources.Bottom["Number"],
                      resources.Bottom["FloorNumber"],
                      resources.Bottom["Locked"],
                    )
                  }
                }}
              />
            )}
            {resources.Room !== undefined && (
              <AccomodationTile
                resource={resources.Room}
                type="room"
                onClick={() => {
                  if (resources.Room !== undefined) {
                    handleSubmit(
                      resources.Room["Id"],
                      resources.Room["Number"],
                      resources.Room["FloorNumber"],
                      resources.Room["Locked"],
                    )
                  }
                }}
              />
            )}
          </div>
        )}
        <div className="mt-12">
          {retry && (
            <Button
              type="button"
              onClick={() => getAvailableResources("true")}
              text="Ask the host and try again"
            />
          )}
        </div>
      </div>
    </div>
  )
}
