import { SVGProps } from "react"

export default function BgCheck(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 1080 979"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-6 h-6"
      {...props}
    >
      <path
        d="M970.799 151.654L622.032 455.448L58.9776 0.00741872L-263.992 281.21L598.011 978.49L619.504 960.032L920.981 697.287L1269.75 393.493L970.799 151.654Z"
        fill="currentColor"
      />
    </svg>
  )
}
