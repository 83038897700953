// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import {
	collection,
	doc,
	getDoc,
	getDocs,
	getFirestore,
	query,
	where,
	onSnapshot,
	updateDoc,
	arrayUnion,
	orderBy,
	DocumentData,
} from "firebase/firestore";
import {
	IBooking,
	IChatRoom,
	IMessage,
	IPlace as ChatIPlace,
} from "../interfaces/chatRoomInterface";
import { IHotelDoc } from "../interfaces/baseInterface";
import { IPlace } from "../features/mapScreen/mapScreenInterface";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
	apiKey: process.env.VITE_FB_API_KEY,
	authDomain: process.env.VITE_FB_AUTH_DOMAIN,
	databaseURL: process.env.VITE_FB_DATABASE_URL,
	projectId: process.env.VITE_FB_PROJECT_ID,
	storageBucket: process.env.VITE_FB_STORAGE_BUCKET,
	messagingSenderId: process.env.VITE_FB_MESSAGING_SENDER_ID,
	appId: process.env.VITE_FB_APP_ID,
	measurementId: process.env.VITE_FB_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const fireDb = getFirestore(app);

/**
 * Update the places reservation id for the hotel
 * @param docId
 * @param placesReservationId
 */
export const updateHotelPlacesReservationId = async (
	docId: string,
	placesReservationId: IHotelDoc["placesReservationId"],
) => {
	const placesDocRef = doc(fireDb, "hotels", docId);
  // console.log(placesDocRef)
	await updateDoc(placesDocRef, {
		placesReservationId,
	});
};

/**
 * Get hotels
 */
export const getHotels = async () => {
	const q = query(collection(fireDb, "hotels"), orderBy("name"));
	const result = await getDocs(q);
	return result;
};

/**
 *
 * @returns Get specific hotel
 */
export const getHotel = async () => {
	let hotel = {} as DocumentData;
	const q = query(collection(fireDb, "hotels"), orderBy("name"));
	const docs = await getDocs(q);
	if (docs.size) {
		docs.forEach((doc) => {
			hotel = doc.data();
			hotel.id = doc.id;
		});
	}
	return hotel;
};

/**
 * Get chatroom by booking id which is the Mews reservation id
 * @param id
 * @returns
 */
export const getChatroomByBookingId = async (id: IBooking["id"]) => {
	const q = query(
		collection(fireDb, "chatRooms"),
		where("booking.id", "==", id),
	);
	const querySnapshot = await getDocs(q);
	return querySnapshot;
};

/**
 * Get chatroom by id which is the user device ID
 * @param id
 * @returns
 */
export const getChatroomByDocId = async (id: IChatRoom["id"]) => {
	try {
		if (id !== null) {
			const docRef = doc(fireDb, "chatRooms", id);
			const room = await getDoc(docRef);
			if (room.exists()) {
        const data = room.data();
				data.id = id;
				return data;
			}
		}
	} catch (error) {
		console.log(error);
	}
};

export const convertPlace = (place: IPlace): ChatIPlace => {
	const newPlace: ChatIPlace = {
		name: place.Name,
		location: {
			lat: place.Lat,
			lng: place.Lng,
		},
		streetAddress: place.Address,
		picture: place.PhotoUrl,
		postalCode: "",
		city: "",
	};
	return newPlace;
};

export const messageWithPlace = (
	chatroom: IChatRoom,
	text: IMessage["text"],
	place: ChatIPlace,
) => {
	const message: IMessage = {
		text,
		sender: {
			firstName: "CityHub",
			picture: null,
		},
		hotelCode: chatroom.hotelCode,
		messageType: "HOST_MESSAGE",
		timetoken: Date.now(),
		type: "default",
		place,
	};

	return message;
};

export const addMessage = async (uuid: IChatRoom["id"], message: IMessage) => {
	try {
		const docRef = doc(fireDb, "chatRooms", uuid);
		await updateDoc(docRef, {
			messages: arrayUnion(message),
		});
	} catch (error) {
		console.error(error);
	}
};
